import { ToastContainer } from 'react-toastify'
import { InAppTemplate } from '../../../shared/templates/portal'
import ReportMenu from './HostelReports'

const HostelReport = () => {
  return (
    <>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      style={{ marginTop: "20px" }}
    />
    <InAppTemplate
      pageTitle="Hostel Management Reports"
      childComponent={< ReportMenu/>}
    />
  </>
  )
}

export default HostelReport
