import React from "react";
import EDocumentationDashboard from "./EDocumentationDashboard";
import { InAppTemplate } from "../../shared/templates/portal";
const EDocumentation = () => {
  return (
    <InAppTemplate
      childComponent={<EDocumentationDashboard />}
      pageTitle="E-Documentation"
    />
  );
};

export default EDocumentation;
