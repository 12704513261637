import { connect } from "react-redux";
import { useEffect } from "react";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { AlertMsg } from "../../shared/components/alert-msg";
import { adminInAppActions } from "../../redux/action/portal";
import { appConstantsPortal } from "../../redux/action-constants/portal";
import { InAppTemplate } from "../../shared/templates/portal";
import "./index.scss";
import Navigation from "../../shared/components/navigation/Navigation";

const adminGetAStudentRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminGetAStudentAction(payload);
};
const adminResetStudentPassswordActionRequest = async ({
  pageProps,
  payload,
}: any) => {
  await pageProps.adminResetStudentPassswordAction(payload);
};

const ResetPassword = ({ pageProps }: any) => {
  let fetchRequestInfo = pageProps.adminGetAStudentRequest;
  fetchRequestInfo = fetchRequestInfo?.request_data?.response?.data;

  let requestInfo = pageProps.adminResetPasswordRequest;

  const handReset = () => {
    let payload: any = {
      matricNumber: fetchRequestInfo?.matricNumber,
    };
    adminResetStudentPassswordActionRequest({ pageProps, payload });
  };

  return (
    <>
      {requestInfo.request_status !==
        appConstantsPortal?.RESET_STUDENT_PASSWORD_SUCCESS && (
        <div className="confirm_reset">
          Do you want to <span>Reset Password</span> for{" "}
          <span>{fetchRequestInfo?.fullName}</span>?
          <div className="note">
            Please note that student will have to login with the new password
            generated.
          </div>
          <button
            className="btn"
            disabled={requestInfo?.is_request_processing}
            onClick={handReset}
          >
            {" "}
            {requestInfo?.is_request_processing
              ? "Please wait..."
              : "Proceed with Reset"}
          </button>
        </div>
      )}
      <div className="student_form">
        {requestInfo.request_status ===
          appConstantsPortal?.RESET_STUDENT_PASSWORD_FAILURE && (
          <AlertMsg type="error" message={requestInfo?.request_data.error} />
        )}

        {requestInfo.request_status ===
          appConstantsPortal?.RESET_STUDENT_PASSWORD_SUCCESS && (
          <AlertMsg
            type="success"
            message={`Password has been  successfully reset for ${fetchRequestInfo?.fullName}`}
          />
        )}
      </div>
    </>
  );
};

const StudentForm = ({ pageProps }: any) => {
  let requestInfo = pageProps.adminGetAStudentRequest;
  let resetRequestInfo = pageProps.adminResetPasswordRequest;

  let checkValidationSchema = Yup.object().shape({
    matricNumber: Yup.string().required("Required"),
  });
  let initialValues: any = { matricNumber: "" };

  return (
    <div className="student_form">
      <Formik
        initialValues={initialValues}
        validationSchema={checkValidationSchema}
        onSubmit={async (values) => {
          let { matricNumber } = values;
          let payload: any = {
            matricNumber,
          };
          adminResetStudentPassswordActionRequest({
            pageProps,
            payload: "CLEAR",
          });
          await adminGetAStudentRequest({ pageProps, payload });
        }}
      >
        {(props: FormikProps<any>) => {
          const {
            values,
            touched,
            errors,
            // handleBlur,
            handleChange,
            // isSubmitting,
          } = props;
          return (
            <Form>
              <div className="form-group">
                <label className="label">Matric Number </label>
                <div
                  className={
                    errors.matricNumber && touched.matricNumber
                      ? "invalid-input-wrap"
                      : ""
                  }
                >
                  <Field
                    value={values.matricNumber}
                    id="matricNumber"
                    name="matricNumber"
                    type="text"
                    disabled={resetRequestInfo?.is_request_processing}
                    onChange={handleChange("matricNumber")}
                    placeholder="Enter matric number"
                    className={`input-wrap ${
                      errors.matricNumber && touched.matricNumber
                        ? "is-invalid-input "
                        : !errors.matricNumber && values.matricNumber !== ""
                        ? "valid-input"
                        : ""
                    }`}
                  />
                </div>
                <ErrorMessage
                  name="matricNumber"
                  className="form-input-error"
                  component="div"
                />
              </div>

              {requestInfo.request_status ===
                appConstantsPortal?.GET_A_STUDENT_FAILURE && (
                <AlertMsg
                  type="error"
                  message={requestInfo?.request_data.error}
                />
              )}
              <div className="submit_cta">
                <button
                  disabled={
                    requestInfo?.is_request_processing ||
                    resetRequestInfo?.is_request_processing
                  }
                  className="btn shaded"
                  type="submit"
                >
                  {requestInfo?.is_request_processing
                    ? "Please wait..."
                    : "Search Student"}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const StudentPasswordSettings = ({ pageProps }: any) => {
  let requestInfo = pageProps.adminGetAStudentRequest;

  useEffect(() => {
    adminGetAStudentRequest({
      pageProps,
      payload: "CLEAR",
    });
    adminResetStudentPassswordActionRequest({
      pageProps,
      payload: "CLEAR",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="student_password settings_wrap_content">
       <div className="back-to-portal">
      <Navigation to="/app/portal-settings" text = "Back to Portal Settings"/>
      </div>
      <StudentForm pageProps={pageProps} />

      {requestInfo.request_status ===
        appConstantsPortal?.GET_A_STUDENT_SUCCESS && (
        <ResetPassword pageProps={pageProps} />
      )}
    </div>
  );
};

const AdminStudentPasswordSettingsWrap = (pageProps: any) => {
  return (
    <InAppTemplate
      pageTitle={`Reset Student Password`}
      childComponent={<StudentPasswordSettings pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  adminGetAStudentAction: adminInAppActions.AdminGetAStudent,
  adminResetStudentPassswordAction:
    adminInAppActions.AdminResetStudentPasssword,
};
const mapStateToProps = (state: any) => ({
  adminGetAStudentRequest: state.allUGradPortalReducers.adminGetAStudentReducer,
  adminResetPasswordRequest:
    state.allUGradPortalReducers.adminResetPasswordReducer,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminStudentPasswordSettingsWrap);
