import { useEffect, useState } from "react";
import { Formik, Form, FormikProps } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";
import DropDown from "../../../shared/components/dropdown";
import { Button } from "react-bootstrap";
import { DotLoader } from "react-spinners";
import { toast } from "react-toastify";
import Navigation from "../../../shared/components/navigation/Navigation";
import DownloadEligibilityModal from "./DowloadEligibilityReport";
import { handleRequestErrors } from "../../../shared/utils";
import { getPutmeEligibilityReport } from "../../../redux/action/putme";
import { getPostUtmeSession } from "../../../redux/action/putme";

const EligibilityReport = () => {
  const [show, setShow] = useState<boolean>(false);
  const [putmeSession, setPutmeSession] = useState<string>("");
  const [downloadUrl, setDownloadUrl] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const fileType = "Excel";

  useEffect(() => {
    const getPutmeSession = async () => {
      try {
        const response = await getPostUtmeSession();
        if (response.status === 200) {
          setPutmeSession(response?.data.session);
        }
      } catch (error) {
        handleRequestErrors(error);
      }
    };
    getPutmeSession();
  }, [putmeSession]);

  return (
    <div className="report-container">
      <div className="back">
        <Navigation to="/app/putme-report-cards" text="Back to Reports" />
      </div>
      <div className="report-page">
        <DownloadEligibilityModal
          show={show}
          setShow={setShow}
          downloadUrl={downloadUrl}
        />
        <Formik
          initialValues={{}}
          onSubmit={async () => {
            setLoading(true);
            const response = await getPutmeEligibilityReport();
            if (response.status === 200) {
              setDownloadUrl(response.data);
              setShow(true);
            } else if (response && response?.length < 1) {
              toast.info("No record found");
            }
            setLoading(false);
          }}
        >
          {(props: FormikProps<any>) => {
            const { touched, errors, setFieldValue } = props;
            return (
              <Form>
                <div className="form-container">
                  <div className="row">
                    <div className="form-group">
                      <div>
                        <DropDown
                          label="Session"
                          name="session"
                          touched={touched}
                          errors={errors}
                          options={[
                            { value: putmeSession, label: putmeSession },
                          ]}
                          field="putmeSession"
                          width="100%"
                          value={{
                            value: putmeSession,
                            label: putmeSession,
                          }}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="report-submit-btn">
                    <Button
                      disabled={loading}
                      type="submit"
                      variant="success"
                      className="download_cta"
                    >
                      {loading ? (
                        <DotLoader
                          color="white"
                          loading={loading}
                          size={30}
                          aria-label="Submitting"
                        />
                      ) : (
                        `Download as ${fileType}`
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default EligibilityReport;
