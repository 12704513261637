import { useState } from "react";

import Table from "react-bootstrap/Table";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import "./index.scss";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "react-tooltip/dist/react-tooltip.css";
import Delete from "../../../assets/delete.png";
import { deleteCarryOverCompulsoryFee } from "../../../redux/action/admission";
import { handleRequestErrors } from "../../../shared/utils";

const AdditionalFeeTable = ({
  items,
  loading,
  fetchCarryOverPayments,
}: any) => {
  const [deleting, setDeleting] = useState(false);
  const [dialogId, setDialogId] = useState<number | null>(null);
  const [showDialog, setShowDialog] = useState(false);

  const handleDelete = async (paymentToDelete: any) => {
    setDeleting(true);
    await deleteCarryOverCompulsoryFee(paymentToDelete)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Payment deleted successfully");
          fetchCarryOverPayments(1);
          setShowDialog(!showDialog);
        }
        setDeleting(false);
        setDialogId(null);
      })
      .catch((err) => {
        toast.error("An error occured, please try again.");
        handleRequestErrors(err);
      });
  };
  const popover = (
    <Popover id="course-delete-tooltip" className="delete-confirmation">
      <h6 style={{ textAlign: "center" }}>Confirm deletion</h6>
      <p>Are you sure you want to delete this item?</p>
      <div className="action-buttons">
        <Button
          variant="danger"
          onClick={() => handleDelete(dialogId)}
          disabled={loading}
        >
          Delete {deleting ? <Spinner animation="border" size="sm" /> : null}
        </Button>
        <Button
          variant="secondary"
          onClick={() => setShowDialog(false)}
          disabled={deleting}
        >
          Cancel
        </Button>
      </div>
    </Popover>
  );
  return (
    <Table bordered hover>
      <thead>
        <tr>
          <th>Matric No.</th>
          <th>Payment type</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          [...Array(20)].map((_, index) => (
            <tr key={index}>
              {[...Array(10)].map((_, index) => (
                <td key={index}>
                  <Skeleton height={20} />
                </td>
              ))}
            </tr>
          ))
        ) : items?.length ? (
          items?.map((item: any, index: any) => {
            return (
              <tr key={item?.uniqueId} id={item?.uniquId}>
                <td>{item?.matricNumber}</td>
                <td>{item?.paymentType}</td>

                {/* PENCIL */}
                <td onClick={async () => {}}>
                  <OverlayTrigger
                    trigger="click"
                    placement="left"
                    show={item?.uniqueId === dialogId && showDialog}
                    overlay={popover}
                    rootClose
                  >
                    <img
                      src={Delete}
                      alt="delete"
                      onClick={async () => {
                        setShowDialog(!showDialog);
                        setDialogId(item?.uniqueId);
                      }}
                      style={{
                        cursor: "pointer",
                        width: "20px",
                        height: "28px",
                      }}
                    />
                  </OverlayTrigger>
                </td>
              </tr>
            );
          })
        ) : (
          <p className="no-results">No results...</p>
        )}
      </tbody>
    </Table>
  );
};

export default AdditionalFeeTable;
