import { connect } from "react-redux";
import { useEffect, useState } from "react";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import Table from "react-bootstrap/Table";
import { InAppTemplate } from "../../shared/templates/portal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import Filter from "../../assets/filter.png";
import { AlertMsg } from "../../shared/components/alert-msg";
import { LoadingItem } from "../../shared/components/loading";
import { adminInAppActions } from "../../redux/action/portal";
import { appConstantsPortal } from "../../redux/action-constants/portal";
import { getDateFromISO } from "../../shared/utils";
import "./index.scss";
import { additionalFees, levelOptions } from "../../shared/_helpers/constants";
import { optionTypes } from "../../types";
import Navigation from "../../shared/components/navigation/Navigation";
import { useSelector } from "react-redux";
const adminGetReportRequest = async ({ pageProps, payload, isSearch }: any) => {
  await pageProps.adminGetFeesReportAction(payload, isSearch);
};

const adminDownloadReportRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminDownloadFeesReportAction(payload);
};

const ReportTable = ({
  reportData,
  searchQuery,
  setSearchQuery,
  setShouldFetch,
  setShowFilter,
}: any) => {
  return (
    <>
      <div className="inputWrap">
        <input
          type="text"
          className="input_wrap search_wrap"
          placeholder="Search"
          value={searchQuery}
          onChange={(e: any) => {
            setSearchQuery(e.target.value);
            setShouldFetch(false);
            setShowFilter(false);
          }}
          onKeyUp={(e: any) => {
            if (searchQuery === "" || e.key === "Enter") {
              setShouldFetch(true);
              setShowFilter(false);
            }
          }}
        />
      </div>

      <Table hover>
        <thead>
          <tr>
            <th>
              <span>Student Name</span>
            </th>
            <th>
              {" "}
              <span>Matric Number</span>
            </th>
            <th>
              {" "}
              <span>Invoice Number</span>
            </th>
            <th>Department</th>
            <th>Level</th>
            <th>State</th>
            <th>Amount Paid</th>

            <th>
              {" "}
              <span>Date Paid</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {reportData &&
            reportData.map((eachData: any, index: any) => {
              return (
                <tr key={index}>
                  <td>{eachData?.fullName} </td>
                  <td>{eachData?.matricNumber} </td>
                  <td>{eachData?.invoiceId} </td>
                  <td>{eachData?.department} </td>
                  <td>{eachData?.level} </td>
                  <td>{eachData?.state} </td>
                  <td>{eachData?.amount} </td>
                  <td>{eachData?.paidOn} </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
};

const LoadRecordsWrap = ({
  pageProps,
  pageSize,
  pageNumber,
  setPageNumber,
  searchQuery,
  setSearchQuery,
  setShouldFetch,
  setShowFilter,
}: any) => {
  let requestData =
    pageProps?.adminFeesReportRequest?.request_data?.response?.data;

  const handlePageClick = (event: any) => {
    setPageNumber(event?.selected + 1);
  };

  return (
    <div className="records_wrap">
      {requestData?.items && (
        <>
          {requestData?.totalCount >= 1 && requestData?.items.length >= 1 && (
            <ReportTable
              reportData={requestData?.items}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              setShouldFetch={setShouldFetch}
              setShowFilter={setShowFilter}
            />
          )}
          {(requestData?.totalCount === 0 ||
            requestData?.items.length === 0) && (
            <div className="empty_record"> No Records in that Date Range</div>
          )}
          {requestData?.totalCount >= 1 && requestData?.items.length >= 1 && (
            <div className="records_count">
              Showing{" "}
              {requestData?.totalCount > requestData?.items.length
                ? `${requestData?.items.length} of ${requestData?.totalCount}`
                : `${requestData?.items.length}`}{" "}
              result{requestData?.items.length > 1 ? "s" : ""}{" "}
            </div>
          )}
          {requestData?.totalCount > pageSize &&
            requestData.items.length >= 1 && (
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                // initialPage={1}
                onPageChange={handlePageClick}
                pageRangeDisplayed={4}
                forcePage={pageNumber - 1}
                // onClick={handleItemsClick}
                className="pagination_items"
                pageClassName="page_num"
                pageLinkClassName="page_link"
                activeClassName="active_page_link"
                previousClassName="previous_page_link"
                nextClassName="next_page_link"
                pageCount={requestData?.totalPages}
                previousLabel="<"
                // @ts-ignore
                renderOnZeroPageCount={null}
              />
            )}
        </>
      )}
    </div>
  );
};

const RecordFiltersWrap = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  userData,
  setSessionFilter,
  sessionFilter,
  isFilterShown,
  setShowFilter,
  setLevelFilter,
  levelFilter,
  searchQuery,
  setSearchQuery,
  setShouldFetch,
  setClearFilter,
  shouldFetch,
  pageProps,
  handleDownload,
  reportType,
  setReportType,
  reportTypeTxt,
  setReportTypeTxt,
  fileType,
  setFileType,
}: any) => {
  const state = useSelector((state: any) => state);
  const sessionHistory =
    state?.allUGradPortalReducers?.adminGetDashboardReducer?.sessionHistory ||
    [];
  const sessionOptions = sessionHistory.map((session: string) => ({
    value: session,
    label: session,
  }));

  let downloadReportRequest = pageProps?.adminDownloadFeesReportRequest;
  const loggedInUser: any = localStorage.getItem("adXAuth") || "{}";

  let user: any = JSON.parse(loggedInUser) || {};
  const userRoles = user?.profile?.userRoles;

  const nannisFilteredFees = additionalFees.filter(
    (list) => list.value === "7"
  );

  const sugFilteredFees = additionalFees.filter((list) => list.value === "4");

  return (
    <>
      {isFilterShown && (
        <Modal
          contentClassName="common-report-modal"
          className="delete_modal"
          show={isFilterShown}
          onHide={() => {
            setShowFilter(false);
          }}
        >
          <Modal.Body className="school-fees-report-filter">
            <div className="records_filter_wrap">
              <div className="level_wrap">
                <label htmlFor="">Fee Payment</label>
                <Select
                  value={{
                    label:
                      userRoles[0] === "Nanniss_Manager"
                        ? "NANNIS FEE"
                        : userRoles[0] === "Sug_Manager"
                        ? "SUG FEE"
                        : reportTypeTxt,
                    value:
                      userRoles[0] === "Nanniss_Manager"
                        ? "NANNIS FEE"
                        : userRoles[0] === "Sug_Manager"
                        ? "SUG FEE"
                        : reportTypeTxt,
                  }}
                  options={
                    userRoles[0] === "Nanniss_Manager"
                      ? nannisFilteredFees
                      : userRoles[0] === "Sug_Manager"
                      ? sugFilteredFees
                      : additionalFees
                  }
                  onChange={(option: optionTypes) => {
                    setReportType(option?.value);
                    setReportTypeTxt(option?.label);
                  }}
                  placeholder="Select payment type"
                  className="select-wrap"
                  classNamePrefix="course-select"
                />
              </div>

              <div className="level_wrap">
                <label htmlFor="">Level</label>
                <Select
                  value={{ label: levelFilter, value: levelFilter }}
                  options={levelOptions}
                  onChange={(option: optionTypes) => {
                    setLevelFilter(option?.value);
                  }}
                  placeholder="Select level"
                  className="select-wrap"
                  classNamePrefix="course-select"
                />
              </div>
            </div>

            <div className="dates_filters">
              <div className="date_wrap">
                <label htmlFor="">Start Date</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date: any) => {
                    setStartDate(date);
                  }}
                  maxDate={new Date()}
                  dateFormat="d MMMM, yyyy"
                  // maxDate={new Date()}
                  placeholderText="Select start date"
                />
              </div>
              <div className="date_wrap">
                <label htmlFor="">End Date</label>
                <DatePicker
                  selected={endDate}
                  onChange={(date: any) => {
                    setEndDate(date);
                  }}
                  maxDate={new Date()}
                  dateFormat="d MMMM, yyyy"
                  // maxDate={new Date()}
                  placeholderText="Select end date"
                />
              </div>
            </div>

            <div className="records_filter_wrap">
              <div className="level_wrap">
                <label htmlFor="">Session</label>
                <Select
                  value={{ label: sessionFilter, value: sessionFilter }}
                  options={sessionOptions}
                  onChange={(option: any) => {
                    if (option?.value !== "") {
                      setSessionFilter(option?.value);
                    }
                  }}
                  className="select-wrap"
                  classNamePrefix="course-select"
                />
              </div>
            </div>
            <div className="filter_proceed">
              <button
                className="btn"
                onClick={() => {
                  setShouldFetch(true);
                  setShowFilter(false);
                }}
              >
                Fetch Report
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <div className="fetch_ctas">
        <div className="filter_summary">
          {sessionFilter} session {levelFilter} level{" "}
          <span>
            {userRoles[0] === "Nanniss_Manager"
              ? "NANNIS FEE "
              : userRoles[0] === "Sug_Manager"
              ? "SUG FEE "
              : reportTypeTxt}
            Report
          </span>{" "}
          from <span>{getDateFromISO({ date: startDate.toISOString() })}</span>{" "}
          to <span>{getDateFromISO({ date: endDate.toISOString() })}</span>
        </div>
      </div>
      <div className="report_filters">
        <div className="ctas">
          <button
            className="btn filter"
            onClick={() => {
              setShowFilter(true);
            }}
          >
            <img src={Filter} alt="" />
            Filter
          </button>

          <Dropdown as={ButtonGroup}>
            <Button
              disabled={downloadReportRequest?.is_request_processing}
              onClick={handleDownload}
              variant="success"
              className="download_cta"
            >
              {downloadReportRequest?.is_request_processing
                ? `Downloading as ${fileType}...`
                : ` Download as ${fileType} `}
            </Button>

            <Dropdown.Toggle
              disabled={downloadReportRequest?.is_request_processing}
              split
              variant="success"
              id="dropdown-split-basic"
            />

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setFileType("CSV")}>
                CSV
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setFileType("EXCEL")}>
                Excel
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setFileType("PDF")}>
                Pdf
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* <button
                        disabled={downloadReportRequest?.is_request_processing}
                        onClick={handleDownload} className="btn">{downloadReportRequest?.is_request_processing ? "Downloading..." : "Download Report"}</button> */}
        </div>
      </div>
    </>
  );
};

const AllRecordsWrap = ({
  pageProps,
  userData,
  defaultStartDate,
  defaultEndDate,
}: any) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [startDate, setStartDate] = useState<any>(defaultStartDate);
  const [endDate, setEndDate] = useState<any>(defaultEndDate);
  const [levelFilter, setLevelFilter] = useState<any>("100");
  const [sessionFilter, setSessionFilter] = useState<any>(
    userData?.currentSession?.session || ""
  );
  const [searchQuery, setSearchQuery] = useState<any>("");
  const [fileType, setFileType] = useState<any>("CSV");
  const [isFilterShown, setShowFilter] = useState<boolean>(false);
  const [shouldFetch, setShouldFetch] = useState<boolean>(false);
  const [isClearFilter, setClearFilter] = useState<boolean>(false);
  const [downloadPayload, setDownloadPayload] = useState<any>("");
  const [reportType, setReportType] = useState<any>("3");
  const [reportTypeTxt, setReportTypeTxt] = useState<any>("FACULTY FEES");
  const pageSize = 20;
  let fetchReportRequest = pageProps?.adminFeesReportRequest;
  let downloadReportRequest = pageProps?.adminDownloadFeesReportRequest;

  const loggedInUser: any = localStorage.getItem("adXAuth") || "{}";

  let user: any = JSON.parse(loggedInUser) || {};
  const userRoles = user?.profile?.userRoles;

  useEffect(() => {
    adminGetReportRequest({
      pageProps,
      payload: "CLEAR",
    });

    let startMonth =
      startDate.getMonth() + 1 < 10
        ? `0${startDate.getMonth() + 1}`
        : startDate.getMonth() + 1;
    let startDay =
      startDate.getDate() < 10
        ? `0${startDate.getDate()}`
        : startDate.getDate();

    let endMonth =
      endDate.getMonth() + 1 < 10
        ? `0${endDate.getMonth() + 1}`
        : endDate.getMonth() + 1;
    let endDay =
      endDate.getDate() < 10 ? `0${endDate.getDate()}` : endDate.getDate();

    let formattedStartDate = `${startDate.getFullYear()}-${startMonth}-${startDay}`;
    let formattedEndDate = `${endDate.getFullYear()}-${endMonth}-${endDay}`;
    let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}&Level=${levelFilter}&Session=${sessionFilter}&StartDate=${formattedStartDate}&EndDate=${formattedEndDate}`;
    setDownloadPayload(payload);
    adminGetReportRequest({
      pageProps,
      payload,
      isSearch: searchQuery.length >= 1,
    });

    adminDownloadReportRequest({
      pageProps,
      payload: "CLEAR",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let startMonth =
      startDate.getMonth() + 1 < 10
        ? `0${startDate.getMonth() + 1}`
        : startDate.getMonth() + 1;
    let startDay =
      startDate.getDate() < 10
        ? `0${startDate.getDate()}`
        : startDate.getDate();

    let endMonth =
      endDate.getMonth() + 1 < 10
        ? `0${endDate.getMonth() + 1}`
        : endDate.getMonth() + 1;
    let endDay =
      endDate.getDate() < 10 ? `0${endDate.getDate()}` : endDate.getDate();

    let formattedStartDate = `${startDate.getFullYear()}-${startMonth}-${startDay}`;
    let formattedEndDate = `${endDate.getFullYear()}-${endMonth}-${endDay}`;
    let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}&Level=${levelFilter}&Session=${sessionFilter}&StartDate=${formattedStartDate}&EndDate=${formattedEndDate}&PaymentType=${reportType}`;
    setDownloadPayload(payload);
    if (searchQuery) {
      payload = `${payload}&SearchQuery=${searchQuery}`;
    }
    adminDownloadReportRequest({
      pageProps,
      payload: "CLEAR",
    });
    adminGetReportRequest({
      pageProps,
      payload,
      isSearch: searchQuery.length >= 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, pageSize]);

  useEffect(() => {
    if (shouldFetch) {
      let startMonth =
        startDate.getMonth() + 1 < 10
          ? `0${startDate.getMonth() + 1}`
          : startDate.getMonth() + 1;
      let startDay =
        startDate.getDate() < 10
          ? `0${startDate.getDate()}`
          : startDate.getDate();

      let endMonth =
        endDate.getMonth() + 1 < 10
          ? `0${endDate.getMonth() + 1}`
          : endDate.getMonth() + 1;
      let endDay =
        endDate.getDate() < 10 ? `0${endDate.getDate()}` : endDate.getDate();

      let formattedStartDate = `${startDate.getFullYear()}-${startMonth}-${startDay}`;
      let formattedEndDate = `${endDate.getFullYear()}-${endMonth}-${endDay}`;
      let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}&Level=${levelFilter}&Session=${sessionFilter}&StartDate=${formattedStartDate}&EndDate=${formattedEndDate}&PaymentType=${reportType}`;
      setDownloadPayload(payload);
      if (searchQuery) {
        payload = `${payload}&SearchQuery=${searchQuery}&FileType=${fileType}`;
      }
      adminDownloadReportRequest({
        pageProps,
        payload: "CLEAR",
      });
      adminGetReportRequest({
        pageProps,
        payload,
        isSearch: searchQuery.length >= 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetch]);

  useEffect(() => {
    if (isClearFilter) {
      let startMonth =
        defaultStartDate.getMonth() + 1 < 10
          ? `0${defaultStartDate.getMonth() + 1}`
          : defaultStartDate.getMonth() + 1;
      let startDay =
        defaultStartDate.getDate() < 10
          ? `0${defaultStartDate.getDate()}`
          : defaultStartDate.getDate();

      let endMonth =
        defaultEndDate.getMonth() + 1 < 10
          ? `0${defaultEndDate.getMonth() + 1}`
          : defaultEndDate.getMonth() + 1;
      let endDay =
        defaultEndDate.getDate() < 10
          ? `0${defaultEndDate.getDate()}`
          : defaultEndDate.getDate();

      let formattedStartDate = `${defaultStartDate.getFullYear()}-${startMonth}-${startDay}`;
      let formattedEndDate = `${defaultEndDate.getFullYear()}-${endMonth}-${endDay}`;

      let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}&Level=${levelFilter}&Session=${sessionFilter}&StartDate=${formattedStartDate}&EndDate=${formattedEndDate}&FileType=${fileType}`;
      if (searchQuery) {
        payload = `${payload}&SearchQuery=${searchQuery}`;
      }
      adminGetReportRequest({
        pageProps,
        payload,
        isSearch: searchQuery.length >= 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClearFilter]);

  const handleDownload = () => {
    let payload = `${downloadPayload}&FileType=${fileType}`;
    adminDownloadReportRequest({
      pageProps,
      payload,
    });
  };

  useEffect(() => {
    if (fetchReportRequest?.is_request_processing === false) {
      setShouldFetch(false);
    }
  }, [fetchReportRequest?.is_request_processing]);

  return (
    <div className="all_reports_wrap">
      {!(
        userRoles[0] === "Nanniss_Manager" || userRoles[0] === "Sug_Manager"
      ) && (
        <div className="back-to-busary-report">
          <Navigation to="/app/bursary-reports" text="Back to Bursary Report" />
        </div>
      )}
      <RecordFiltersWrap
        pageProps={pageProps}
        handleDownload={handleDownload}
        setStartDate={setStartDate}
        startDate={startDate}
        setEndDate={setEndDate}
        endDate={endDate}
        setLevelFilter={setLevelFilter}
        levelFilter={levelFilter}
        setSessionFilter={setSessionFilter}
        sessionFilter={sessionFilter}
        setFileType={setFileType}
        fileType={fileType}
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        userData={userData}
        isFilterShown={isFilterShown}
        setShowFilter={setShowFilter}
        setShouldFetch={setShouldFetch}
        shouldFetch={shouldFetch}
        setClearFilter={setClearFilter}
        reportType={reportType}
        setReportType={setReportType}
        reportTypeTxt={reportTypeTxt}
        setReportTypeTxt={setReportTypeTxt}
      />

      {fetchReportRequest?.request_status ===
        appConstantsPortal.GET_BURSARY_ADDITIONALFEE_REPORTS_PENDING && (
        <LoadingItem />
      )}
      {fetchReportRequest?.request_status ===
        appConstantsPortal.GET_BURSARY_ADDITIONALFEE_REPORTS_FAILURE && (
        <AlertMsg
          type="error"
          message={fetchReportRequest?.request_data.error}
        />
      )}
      {downloadReportRequest?.request_status ===
        appConstantsPortal.DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_FAILURE && (
        <AlertMsg
          type="error"
          message={downloadReportRequest?.request_data.error}
        />
      )}

      {downloadReportRequest?.request_status ===
        appConstantsPortal.DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_SUCCESS && (
        <div className="alert-msg success">
          <div className="alert-txt">
            You can now{" "}
            <a
              className="dl_link"
              target="_blank"
              rel="noreferrer"
              href={downloadReportRequest?.request_data?.response?.data}
            >
              download the report
            </a>{" "}
          </div>
        </div>
      )}
      {fetchReportRequest?.request_status ===
        appConstantsPortal.GET_BURSARY_ADDITIONALFEE_REPORTS_SUCCESS && (
        <LoadRecordsWrap
          pageProps={pageProps}
          pageSize={pageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          setShouldFetch={setShouldFetch}
          setShowFilter={setShowFilter}
        />
      )}
    </div>
  );
};

const AdminAdditionalFeeReportWrap = (pageProps: any) => {
  let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
  loggedAccountInfo = JSON.parse(loggedAccountInfo);
  let defaultStartDate = new Date(new Date().setDate(new Date().getDate() - 7));
  let defaultEndDate = new Date();

  return (
    <InAppTemplate
      pageTitle="Generate Additional Fees Report"
      childComponent={
        <AllRecordsWrap
          pageProps={pageProps}
          userData={loggedAccountInfo}
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
        />
      }
    />
  );
};

const mapDispatchToProps = {
  adminGetFeesReportAction: adminInAppActions.AdminGetAdditionalFeesReport,
  adminDownloadFeesReportAction:
    adminInAppActions.AdminDownloadAdditionalFeesReport,
};
const mapStateToProps = (state: any) => ({
  adminFeesReportRequest:
    state.allUGradPortalReducers.adminGetAdditionalFeesReportReducer,

  adminDownloadFeesReportRequest:
    state.allUGradPortalReducers.adminDownloadAdditionalFeesReportReducer,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminAdditionalFeeReportWrap);
