import React, { useState } from 'react'
import Filter from "../../../../assets/filter.png";
import "./index.scss"
import Navigation from '../../../../shared/components/navigation/Navigation';
import ReportModal from './ReportModal';

  const ReportDownload = () => {
  
  const [showFilter, setShowFilter] = useState(false);
  
  return (
    <div className='hostel-report-filter'>
      <div className="back">
          <Navigation to="/app/hostels/reports" text="Back to Reports" />
        </div>

      <div className="download-wrap">
        <div className="filter_summary">
          <button
            className="btn filter"
            onClick={() => setShowFilter(true)}
          >
            <img src={Filter} alt="" />
            Select Date
          </button>
        </div>  
      </div>
        
      <ReportModal showFilter={showFilter} setShowFilter={setShowFilter}/>
      
    </div>
  )
}

export default ReportDownload;
