import React from "react";
import { InAppTemplate } from "../../shared/templates/portal";
import RoomsTable from "./RoomsTable";
import { ToastContainer } from "react-toastify";

const Rooms = () => {
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <InAppTemplate
        pageTitle="Hostel Accomodation Dashbord"
        childComponent={<RoomsTable />}
      />
    </>
  );
};

export default Rooms;
