import React, { useCallback, useEffect, useState } from "react";
import { CiCirclePlus } from "react-icons/ci";
import "./index.scss";
import SearchBox from "../../../shared/components/searchbox";
import { NavLink } from "react-router-dom";
import AdditionalFeeTable from "./AdditionalFeesTable";
import AdditionalPaymentModal from "./AdditionalPaymentModal";
import { ToastContainer } from "react-toastify";
import {
  getAllCarryOverAddedPayments,
  searchCarryOverCompulsoryFee,
} from "../../../redux/action/admission";
import { handleRequestErrors } from "../../../shared/utils";
import ReactPaginate from "react-paginate";
import Navigation from "../../../shared/components/navigation/Navigation";

const AllAddtionalPayment = () => {
  const [searchParameter, setSearchParameter] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [paymentList, setPaymentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const pageCount = Math.ceil(totalCount / 20);
  const fetchCarryOverPayments = async (
    pageNumber: number,
    showLoading: boolean = false
  ) => {
    showLoading && setLoading(true);
    await getAllCarryOverAddedPayments(pageNumber)
      .then(({ data }) => {
        setPaymentList(data?.items);
        setTotalCount(data?.totalCount);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        handleRequestErrors(err);
      });
  };

  const handlePageClick = (event: any) => {
    const newOffset = event.selected + 1;
    fetchCarryOverPayments(newOffset);
  };

  const handleSearch = useCallback(
    async (searchParam: any, pageNumber: number) => {
      await searchCarryOverCompulsoryFee(searchParam, pageNumber)
        .then(({ data }) => {
          setPaymentList(data?.items);
          setTotalCount(data?.totalCount);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          handleRequestErrors(err);
        });
    },
    []
  );

  useEffect(() => {
    let searchDebounce: any;
    if (searchParameter.length) {
      searchDebounce = setTimeout(() => {
        handleSearch(searchParameter, 1);
      }, 1000);
    } else {
      fetchCarryOverPayments(1, true);
    }
    return () => clearTimeout(searchDebounce);
  }, [searchParameter, handleSearch]);

  return (
    <div className="additional-payment-contaianer">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
       <div className="back-to-portal">
      <Navigation to="/app/portal-settings" text = "Back to Portal Settings"/>
      </div>
      <div className="additional-fee-header">
        <SearchBox
          placeholder="Search..."
          setSearchParameter={setSearchParameter}
          searchParameter={searchParameter}
        />

        <div className="action-button" onClick={() => setShowModal(true)}>
          {" "}
          <CiCirclePlus style={{ width: "20px", height: "20px" }} />
          <span>Add</span>
        </div>
      </div>
      <AdditionalFeeTable
        items={paymentList}
        loading={loading}
        fetchCarryOverPayments={fetchCarryOverPayments}
      />
      {paymentList?.length ? (
        <div className="result-count">
          <p>
            Showing {paymentList?.length} of {totalCount} results
          </p>
        </div>
      ) : null}
      <AdditionalPaymentModal
        show={showModal}
        setShow={setShowModal}
        fetchCarryOverPayments={fetchCarryOverPayments}
      />
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="pagination_items"
        pageClassName="page_num"
        pageLinkClassName="page_link"
        activeClassName="active_page_link"
        previousClassName="previous_page_link"
        nextClassName="next_page_link"
      />
    </div>
  );
};

export default AllAddtionalPayment;
