import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { AlertMsg } from "../../shared/components/alert-msg";
import Table from "react-bootstrap/Table";
import Select from "react-select";

import { adminInAppActions } from "../../redux/action/portal";
import { appConstantsPortal } from "../../redux/action-constants/portal";
import { InAppTemplate } from "../../shared/templates/portal";
import "./index.scss";
import Navigation from "../../shared/components/navigation/Navigation";

const adminGetAStudentActionRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminGetAUserAction(payload, true);
};
const adminAssignCourseAdvToDeptActionRequest = async ({
  pageProps,
  payload,
}: any) => {
  await pageProps.adminAssignCourseAdvToDeptAction(payload);
};

const SuccessfulCourseUpdate = ({ request, studentData }: any) => {
  return (
    <div className="update_success">
      <div className="success-message-wrap">
        <div className="success-animation">
          <svg
            className="success_icon_tick"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              className="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
            />
            <path
              className="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>
        </div>
        <div className="success-message-txt">
          {" "}
          Department successfully aassigned
        </div>
      </div>
    </div>
  );
};

const UpdateStatus = ({ pageProps, userId }: any) => {
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showPending, setShowPending] = useState<boolean>(false);
  const [allDeptsInFaculty, setAllDeptsInFaculty] = useState<any[]>([]);
  const [chosenDept, setChosenDept] = useState<any>();
  const [chosenFaculty, setChosenFaculty] = useState<any>();
  const [chosenProgramme, setChosenProgramme] = useState<any>();

  let fetchRequestInfo = pageProps.adminGetAUserRequest;
  fetchRequestInfo = fetchRequestInfo?.request_data?.response?.data;
  let requestInfo = pageProps.adminAssignCourseAdvToDeptRequest;

  let allData = pageProps.adminGetAUserRequest?.request_data?.response;
  let facultiesList = allData?.allDeptAndFaculty;
  let defaultDept = fetchRequestInfo?.departmentName || "Not assigned";
  let defaultFaculty = fetchRequestInfo?.departmentName
    ? facultiesList.filter(
        (eachItem: any) => eachItem?.departmentName === defaultDept
      )[0]?.faculty?.facultyName
    : "Not assigned";
  useEffect(() => {
    if (chosenFaculty?.value) {
      let allDept: any[] = [];
      setChosenProgramme("");
      setChosenDept("");
      allData.allDeptAndFaculty.forEach((eachData: any) => {
        if (
          allDept.findIndex(
            (dept: any) => dept?.value === eachData?.departmentUniqueId
          ) === -1 &&
          eachData?.faculty?.facultyUniqueId === chosenFaculty?.value
        ) {
          allDept.push({
            value: eachData?.departmentUniqueId,
            label: eachData?.departmentName,
          });
        }
      });
      setAllDeptsInFaculty(allDept);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenFaculty]);

  useEffect(() => {
    if (chosenDept?.value) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chosenProgramme]);

  let allFaculties: any = [];

  allData.allDeptAndFaculty.forEach((eachData: any) => {
    if (
      allFaculties.findIndex(
        (faculty: any) => faculty?.value === eachData?.faculty?.facultyUniqueId
      ) === -1
    ) {
      allFaculties.push({
        value: eachData?.faculty?.facultyUniqueId,
        label: eachData?.faculty?.facultyName,
      });
    }
  });

  const saveDept = (selectedDept: any) => {
    setChosenDept(selectedDept);
    // setChosenProgramme({})
  };
  const saveFaculty = (selectedFaculty: any) => {
    setChosenFaculty(selectedFaculty);
    // setChosenDept({})
  };

  let initialValues: any = {};

  return (
    <>
      {requestInfo.request_status !==
        appConstantsPortal?.ASSIGN_COURSE_ADVISOR_TO_DEPT_SUCCESS && (
        <div className="settingform_wrap">
          <span>{fetchRequestInfo?.fullName}</span>

          <div className="student_form regular">
            <Formik
              initialValues={initialValues}
              onSubmit={async (values) => {
                let payload: any = {
                  searchTerm: userId,
                  departmentName: chosenDept?.label,
                };

                await adminAssignCourseAdvToDeptActionRequest({
                  pageProps,
                  payload,
                });
              }}
            >
              {(props: FormikProps<any>) => {
                return (
                  <Form>
                    <Table hover className="info_table" bordered={true}>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Present Department</th>
                          <th>New Department</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Faculty</td>
                          <td>{defaultFaculty}</td>
                          <td>
                            <div>
                              <Select
                                // menuIsOpen={menuToOPen === "faculty" ? true : false}
                                value={chosenFaculty}
                                id="chosenFaculty"
                                openMenuOnClick={true}
                                onChange={saveFaculty}
                                options={allFaculties}
                                placeholder="Select faculty"
                                className="select-wrap"
                                classNamePrefix="faculty-select"
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Department</td>
                          <td>{defaultDept}</td>
                          <td>
                            <div className="">
                              <Select
                                // menuIsOpen={menuToOPen === "dept" ? true : false}
                                value={chosenDept}
                                onChange={saveDept}
                                options={allDeptsInFaculty}
                                isDisabled={!chosenFaculty?.value}
                                placeholder="Select department"
                                className="select-wrap"
                                classNamePrefix="dept-select"
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    {requestInfo.request_status ===
                      appConstantsPortal?.GET_A_USER_FAILURE && (
                      <AlertMsg
                        type="error"
                        message={requestInfo?.request_data.error}
                      />
                    )}
                    {showEdit && (
                      <div className="submit_cta">
                        <AlertMsg
                          type="error"
                          message={`Please confirm you want to assign  ${fetchRequestInfo.fullName}  to ${chosenDept?.label} to Preset courses for Students `}
                        />

                        {requestInfo.request_status ===
                          appConstantsPortal?.ASSIGN_COURSE_ADVISOR_TO_DEPT_FAILURE && (
                          <AlertMsg
                            type="error"
                            message={requestInfo?.request_data.error}
                          />
                        )}
                        <button
                          disabled={requestInfo?.is_request_processing}
                          className="btn shaded"
                          type="submit"
                        >
                          {requestInfo?.is_request_processing
                            ? "Please wait..."
                            : "Assign User To Department"}
                        </button>
                      </div>
                    )}
                    {showPending && (
                      <AlertMsg
                        type="error"
                        message="Select FACULTY, DEPARTMENT"
                      />
                    )}
                    {!showEdit && (
                      <div className="submit_cta">
                        <button
                          onClick={() => {
                            if (!chosenDept?.label) {
                              setShowPending(true);
                            } else {
                              setShowPending(false);
                              setShowEdit(true);
                            }
                          }}
                          disabled={requestInfo?.is_request_processing}
                          className="btn shaded"
                          type="button"
                        >
                          Click to Assign User To Department
                        </button>
                      </div>
                    )}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
      <div className="student_form"></div>
    </>
  );
};

const StudentForm = ({ pageProps, setUserId }: any) => {
  let requestInfo = pageProps.adminGetAUserRequest;
  let updateStatusRequestInfo = pageProps.adminAssignCourseAdvToDeptRequest;

  useEffect(() => {
    adminGetAStudentActionRequest({
      pageProps,
      payload: "CLEAR",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let checkValidationSchema = Yup.object().shape({
    searchTerm: Yup.string().required("Required"),
  });
  let initialValues: any = { searchTerm: "" };

  return (
    <>
      <div className="student_form">
        <Formik
          initialValues={initialValues}
          validationSchema={checkValidationSchema}
          onSubmit={async (values) => {
            let { searchTerm } = values;
            let payload: any = {
              searchTerm,
            };
            adminAssignCourseAdvToDeptActionRequest({
              pageProps,
              payload: "CLEAR",
            });
            await adminGetAStudentActionRequest({ pageProps, payload });
            setUserId(searchTerm);
          }}
        >
          {(props: FormikProps<any>) => {
            const {
              values,
              touched,
              errors,
              // handleBlur,
              handleChange,
              // isSubmitting,
            } = props;
            return (
              <Form>
                <div className="form-group">
                  <label className="label">Username/Email </label>
                  <div
                    className={
                      errors.searchTerm && touched.searchTerm
                        ? "invalid-input-wrap"
                        : ""
                    }
                  >
                    <Field
                      value={values.searchTerm}
                      id="searchTerm"
                      name="searchTerm"
                      type="text"
                      disabled={updateStatusRequestInfo?.is_request_processing}
                      onChange={handleChange("searchTerm")}
                      placeholder="Enter username or email"
                      className={`input-wrap ${
                        errors.searchTerm && touched.searchTerm
                          ? "is-invalid-input "
                          : !errors.searchTerm && values.searchTerm !== ""
                          ? "valid-input"
                          : ""
                      }`}
                    />
                  </div>
                  <ErrorMessage
                    name="searchTerm"
                    className="form-input-error"
                    component="div"
                  />
                </div>

                {requestInfo.request_status ===
                  appConstantsPortal?.GET_A_USER_FAILURE && (
                  <AlertMsg
                    type="error"
                    message={requestInfo?.request_data.error}
                  />
                )}
                <div className="submit_cta">
                  <button
                    disabled={
                      requestInfo?.is_request_processing ||
                      updateStatusRequestInfo?.is_request_processing
                    }
                    className="btn shaded"
                    type="submit"
                  >
                    {requestInfo?.is_request_processing
                      ? "Please wait..."
                      : "Search "}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

const UpdateSettingsWrap = ({ pageProps }: any) => {
  const [userId, setUserId] = useState("");
  let requestInfo = pageProps.adminGetAUserRequest;
  let updateRequestInfo = pageProps.adminAssignCourseAdvToDeptRequest;

  useEffect(() => {
    adminGetAStudentActionRequest({
      pageProps,
      payload: "CLEAR",
    });
    adminAssignCourseAdvToDeptActionRequest({
      pageProps,
      payload: "CLEAR",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="student_password settings_wrap_content">
       <div className="back-to-portal">
      <Navigation to="/app/portal-settings" text = "Back to Portal Settings"/>
      </div>
      {updateRequestInfo.request_status !==
        appConstantsPortal?.ASSIGN_COURSE_ADVISOR_TO_DEPT_SUCCESS && (
        <>
          <StudentForm setUserId={setUserId} pageProps={pageProps} />
        </>
      )}
      {requestInfo.request_status === appConstantsPortal?.GET_A_USER_SUCCESS &&
        userId && <UpdateStatus pageProps={pageProps} userId={userId} />}
      {updateRequestInfo.request_status ===
        appConstantsPortal?.ASSIGN_COURSE_ADVISOR_TO_DEPT_SUCCESS && (
        <SuccessfulCourseUpdate
          request={updateRequestInfo}
          studentData={requestInfo?.request_data}
        />
      )}
    </div>
  );
};

const AdminAssignDeptToCourseAdvisorWrap = (pageProps: any) => {
  return (
    <InAppTemplate
      pageTitle={`Assign Department to Course Advisor`}
      childComponent={<UpdateSettingsWrap pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  adminGetAUserAction: adminInAppActions.AdminGetAUser,
  adminAssignCourseAdvToDeptAction:
    adminInAppActions.AdminAssignCourseAdvToDept,
};
const mapStateToProps = (state: any) => ({
  adminGetAUserRequest: state.allUGradPortalReducers.adminGetAUserReducer,
  adminAssignCourseAdvToDeptRequest:
    state.allUGradPortalReducers.adminAssignCourseAdvToDeptReducer,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminAssignDeptToCourseAdvisorWrap);
