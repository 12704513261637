import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AlertMsg } from "../../../shared/components/alert-msg";
import Logo from "../../../assets/logo2.png";
import PwSeen from "../../../assets/pw-visible.png";
import PwHide from "../../../assets/pw-hidden.png";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { adminOnboarding } from "../../../redux/action/onboarding";
import { appConstantsOnboarding } from "../../../redux/action-constants/onboarding";

import "../index.scss";
import { Link } from "react-router-dom";
import { clearState } from "../../../redux/action/admission";

const adminLoginActionRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminLoginAction(payload);
};

const AdminLogin = (pageProps: any) => {
  const [isPwShown, setShowPw] = useState<boolean>(false);
  const screenResolution: any = useWindowSize();
  const reloadVariable = localStorage.getItem("adRld");

  let requestInfo = pageProps?.adminLoginRequest;

  let yearTxt = new Date().getFullYear();

  useEffect(() => {
    adminOnboarding.Logout();
    pageProps.clearStateAction();
    pageProps.clearState();
    adminLoginActionRequest({
      pageProps,
      payload: "CLEAR",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !reloadVariable &&
      requestInfo.request_status !== appConstantsOnboarding?.LOGIN_USER_FAILURE
    ) {
      localStorage.setItem("adRld", JSON.stringify(true));
      window.location.reload();
    }
  }, [reloadVariable]);

  let checkValidationSchema = Yup.object().shape({
    userName: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });
  let initialValues: any = { userName: "", password: "" };
  return (
    <div className="onboarding_wrap">
      <div className="login-form-wrapper">
        <div className="onboarding_content splitted">
          {screenResolution?.width >= 767 && (
            <div className="each_side summary">
              <div>
                <div className="logo">
                  <img src={Logo} alt="" />
                </div>
                <div className="school_name">
                  IBRAHIM BADAMASI BABANGIDA UNIVERSITY
                </div>
                <div className="student_type_txt">ADMIN LOGIN</div>
              </div>
            </div>
          )}
          {screenResolution?.width <= 766 && (
            <div className="mobile_heading">
              <div className="logo">
                <img src={Logo} alt="" />
              </div>
              <div className="school_name">
                IBRAHIM BADAMASI BABANGIDA UNIVERSITY
              </div>
              {/* <div className="student_type_txt">FULL-TIME  STUDENT <br /> SIGN IN</div> */}
            </div>
          )}
          <div className="each_side form_info">
            <div className="form_heading">WELCOME, PLEASE LOGIN</div>
            <Formik
              initialValues={initialValues}
              validationSchema={checkValidationSchema}
              onSubmit={async (values) => {
                let { userName, password } = values;
                let payload: any = {
                  userName,
                  password,
                };

                await adminLoginActionRequest({ pageProps, payload });
              }}
            >
              {(props: FormikProps<any>) => {
                const {
                  values,
                  touched,
                  errors,
                  // handleBlur,
                  handleChange,
                  // isSubmitting,
                } = props;
                return (
                  <Form>
                    <div className="form-group">
                      <label className="label">Username </label>
                      <div
                        className={
                          errors.userName && touched.userName
                            ? "invalid-input-wrap"
                            : ""
                        }
                      >
                        <Field
                          value={values.userName}
                          id="userName"
                          name="userName"
                          type="text"
                          onChange={handleChange("userName")}
                          placeholder="Enter username"
                          className={`input-wrap ${
                            errors.userName && touched.userName
                              ? "is-invalid-input "
                              : !errors.userName && values.userName !== ""
                              ? "valid-input"
                              : ""
                          }`}
                        />
                      </div>
                      <ErrorMessage
                        name="userName"
                        className="form-input-error"
                        component="div"
                      />
                    </div>
                    <div className="form-group">
                      <label className="label">Password </label>
                      <div
                        className={
                          errors.password && touched.password
                            ? "invalid-input-wrap password_wrap"
                            : " password_wrap"
                        }
                      >
                        <Field
                          value={values.password}
                          id="password"
                          name="password"
                          type={!isPwShown ? "password" : "text"}
                          onChange={handleChange("password")}
                          placeholder="Enter Password"
                          className={`input-wrap ${
                            errors.password && touched.password
                              ? "is-invalid-input "
                              : !errors.password && values.password !== ""
                              ? "valid-input"
                              : ""
                          }`}
                        />
                        <div
                          className="show-hide"
                          onClick={() => setShowPw(!isPwShown)}
                        >
                          {isPwShown && <img src={PwHide} alt="" />}
                          {!isPwShown && <img src={PwSeen} alt="" />}
                        </div>
                      </div>
                      <ErrorMessage
                        name="password"
                        className="form-input-error"
                        component="div"
                      />
                    </div>
                    {requestInfo.request_status ===
                      appConstantsOnboarding?.LOGIN_USER_FAILURE && (
                      <AlertMsg
                        type="error"
                        message={requestInfo?.request_data.error}
                      />
                    )}
                    <div className="submit_cta">
                      <button
                        disabled={requestInfo?.is_request_processing}
                        className="btn shaded"
                        type="submit"
                      >
                        {requestInfo?.is_request_processing
                          ? "Please wait..."
                          : "Login"}
                      </button>
                    </div>

                    <div className="forgot_cta">
                      Forgot your password ?{" "}
                      <Link to="/accounts/forgot-password">Get it Here</Link>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      {/* {screenResolution?.width >= 767 && ( */}
      <div className="login-footer">
        <p>
          {" "}
          <span>&copy; {yearTxt}</span> IBB University. All rights reserved
        </p>
        <p>
          Developed by{" "}
          <Link
            to="https://gigbadi.com.ng/"
            target="_blank"
            className="footer-link"
          >
            Gigbadi
          </Link>
        </p>
      </div>
      {/* )} */}
    </div>
  );
};

const mapDispatchToProps = {
  adminLoginAction: adminOnboarding.AdminLogin,
  clearStateAction: adminOnboarding.clearStateAction,
  clearState,
};
const mapStateToProps = (state: any) => ({
  adminLoginRequest: state.allOnboardingReducers.adminLogin,
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);
