import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { adminOnboarding, resetReload } from "../../../redux/action/onboarding";
import { NavLink } from "react-router-dom";
import { menuList } from "./SideMenu";
import "./index.scss";

// type OwnProps = HeaderProps & ReturnType<typeof mapStateToProps>;
export const SideBarWrap = ({ pageProps }: any) => {
  const route = useLocation();
  // let loggedInData = props.loginRequest.request_data.response
  let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";

  let userRoles: any[];

  if (loggedAccountInfo) {
    loggedAccountInfo = JSON.parse(loggedAccountInfo);

    userRoles = loggedAccountInfo?.profile?.userRoles || [];
    userRoles = userRoles.filter((c: any, index: any) => {
      return userRoles.indexOf(c) === index;
    });
  }

  const MenuItems = () => {
    return (
      <div className="all-menu-items">
        {menuList.map(
          ({ routeTo, icon, name, accessRoles }: any, index: any) => {
            let allowedRoles = accessRoles || [];
            let canUserAccess: boolean = false;

            for (let index = 0; index < userRoles.length; index++) {
              const element = userRoles[index];
              if (allowedRoles.includes(element)) {
                canUserAccess = true;
                break;
              } else {
              }
            }

            if (canUserAccess || allowedRoles.length === 0) {
              return (
                <div
                  key={index}
                  className="menu-item"
                  onClick={() => {
                    if (name === "Logout") {
                      resetReload();
                      adminOnboarding.Logout();
                    }
                  }}
                >
                  <NavLink
                    end={name === "Dashboard"}
                    className={({ isActive }) =>
                      isActive ||
                      (name === "Post UTME" &&
                        route.pathname.includes("postutme"))
                        ? "active-nav"
                        : ""
                    }
                    to={routeTo}
                  >
                    {icon ? (
                      <div className="menu-icon">
                        <span>
                          <img src={icon} alt="menu icon" />
                        </span>
                      </div>
                    ) : (
                      <div className="menu-icon logout-ico">
                        <span></span>
                      </div>
                    )}
                    <div
                      className={`${
                        name === "Logout" ? "menu-txt logout" : "menu-txt"
                      } `}
                    >
                      {name}
                    </div>
                  </NavLink>
                </div>
              );
            } else {
              return "";
            }

            // if (routeTo !== "/app/portal-settings/reset-student-password") {
            //   return (
            //     <div
            //       key={name}
            //       className="menu-item"
            //       onClick={name === "Logout" ? pageProps.logoutAction : null}
            //     >
            //       <NavLink
            //         end
            //         className={({ isActive }) =>
            //           isActive ||
            //             (name === "Post UTME" &&
            //               route.pathname.includes("postutme"))
            //             ? "active-nav"
            //             : ""
            //         }
            //         to={routeTo}
            //       >
            //         {icon ? (
            //           <div className="menu-icon">
            //             <span>
            //               <img src={icon} alt="menu icon" />
            //             </span>
            //           </div>
            //         ) : (
            //           <div className="menu-icon logout-ico">
            //             <span></span>
            //           </div>
            //         )}
            //         <div
            //           className={`${name === "Logout" ? "menu-txt logout" : "menu-txt"
            //             } `}
            //         >
            //           {name}
            //         </div>
            //       </NavLink>
            //     </div>
            //   );
            // } else {
            //   return <></>;
            // }
          }
        )}
        {/* {decodedToken?.role == "Password_Resetter" && (
          <>
            <div className="menu-item">
              <NavLink
                end
                className={({ isActive }) => (isActive ? "active-nav" : "")}
                to="/app/portal-settings/reset-student-password"
              >
                <div className="menu-icon">
                  <span>
                    <img src={Settings} alt="menu icon" />
                  </span>
                </div>
                <div className="menu-txt">Reset Password</div>
              </NavLink>
            </div>
            <div className="menu-item" onClick={pageProps.logoutAction}>
              <NavLink
                end
                className={({ isActive }) => (isActive ? "active-nav" : "")}
                to="/"
              >
                <div className="menu-icon logout-ico">
                  <span></span>
                </div>
                <div className="menu-txt logout">Logout</div>
              </NavLink>
            </div>
          </>
        )} */}
      </div>
    );
  };

  return (
    <div
      className={
        !pageProps?.isMobile ? "sidebar-wrap" : "sidebar-wrap withmobile"
      }
    >
      {pageProps?.isMobile && (
        <div
          className="sidebar-close"
          onClick={() => pageProps?.setShowVisible(false)}
        >
          {/* <img src={CloseIco} alt="" /> */}
        </div>
      )}

      <MenuItems />
    </div>
  );
};

const SideBar = (pageProps: any, isMobile?: any) => {
  return (
    <>
      {pageProps?.isMobile && <div className="underlay_mobile"></div>}
      <SideBarWrap pageProps={pageProps} isMobile={isMobile} />
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    // loginRequest: state.onboardingOutboundReducers.loginReducer,
  };
};
export default connect(mapStateToProps)(SideBar);
