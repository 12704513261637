import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { InAppTemplate } from "../../shared/templates/portal/";
import "./index.scss";

const ReportsDashboard = ({ pageProps }: any) => {
  return (
    <div className="settings_list">
      <div className="each_setting">
        <div className="setting_name">School Fees Payments</div>
        <div className="setting_link">
          <Link className="btn" to="/app/bursary-reports/school-fees">
            Generate Reports
          </Link>{" "}
        </div>
      </div>
      <div className="each_setting">
        <div className="setting_name">Acceptance Fee Payments</div>
        <div className="setting_link">
          <Link className="btn" to="/app/bursary-reports/acceptance-fees">
            Generate Reports
          </Link>{" "}
        </div>
      </div>
        <div className="each_setting">
        <div className="setting_name">Additional Fee Payments</div>
        <div className="setting_link">
          <Link className="btn" to="/app/bursary-reports/additional-fees">
            Generate Reports
          </Link>{" "}
        </div>
      </div>
    </div>
  );
};

const AdminBursaryReportsWrap = (pageProps: any) => {
  return (
    <InAppTemplate
      pageTitle={`Bursary Reports`}
      childComponent={<ReportsDashboard pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {};
const mapStateToProps = (state: any) => ({});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminBursaryReportsWrap);
