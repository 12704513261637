import { connect } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { adminOnboarding, resetReload } from "../../../redux/action/onboarding";
import Logo from "../../../assets/logo2.png";
import "./index.scss";
import { menuList } from "../sidebar/SideMenu";

const AppHeader = (props: any) => {
  let loggedInfo = props?.adminLoginRequest?.request_data?.username;
  let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";

  let userRoles: any[];
  if (loggedAccountInfo) {
    loggedAccountInfo = JSON.parse(loggedAccountInfo);

    userRoles = loggedAccountInfo?.profile?.userRoles || [];
    userRoles = userRoles.filter((c: any, index: any) => {
      return userRoles.indexOf(c) === index;
    });
  }

  const userRole = loggedAccountInfo?.profile?.userRoles || [];

  const dropDownMenuList = menuList.filter((item: any) =>{
    return item.name !== "Logout"
  })


  const logout = () => {
    adminOnboarding.Logout();
  };

  return (
    <div className="ad_portal_header">
      <div className="left_wrap">
        <div className="logo-wrap">
          <a href="https://ibbu.edu.ng/" target="_blank" rel="noreferrer">
            <img src={Logo} alt="Admission Logo" />
          </a>
        </div>
        <div className="head_txt_wrap">
          <div className="portal_mode">Undergraduate Admin Portal</div>
          <div className="school_name_txt">
            Ibrahim Badamasi Babangida University
          </div>
        </div>
      </div>

      {!(
        userRole[0] === "Nanniss_Manager" || userRole[0] === "Sug_Manager"
      ) && (
        <div className="user_info_wrap">
          <Dropdown className="report_dropdown">
            <Dropdown.Toggle id="dropdown-basic" className="action-cta">
              <div className="profile_drop">
                {/* <div className="user_ico"><img src={UserIco} alt="" /></div> */}
                {loggedInfo}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {dropDownMenuList.map(
                ({ routeTo, name, accessRoles }: any, index: any) => {
                  let allowedRoles = accessRoles || [];
                  let canUserAccess: boolean = false;

                  for (let index = 0; index < userRoles.length; index++) {
                    const element = userRoles[index];
                    if (allowedRoles.includes(element)) {
                      canUserAccess = true;
                      break;
                    }
                  }
                  if (canUserAccess || allowedRoles.length === 0) {
                    return (
                      <Dropdown.Item key={index} href={routeTo}>
                        {" "}
                        {name}
                      </Dropdown.Item>
                    );
                  } else {
                    return "";
                  }
                }
              )}
              {/* {(decodedToken?.role == "Course_Advisor" || decodedToken?.role == "Admin" ) && <Dropdown.Item href={`/app/course-management/undergraduate`}> Courses</Dropdown.Item>}
                       {decodedToken?.role == "Admin" && <Dropdown.Item href={`/app/students`}> Students</Dropdown.Item>}
                       {decodedToken?.role == "Admin" && <Dropdown.Item href={`/app/admissions-management`}> Admissions</Dropdown.Item>}
                       {decodedToken?.role == "Admin" &&<Dropdown.Item href={`/app/postutme`}> Post UTME</Dropdown.Item>}
                       {decodedToken?.role == "Admin" &&<Dropdown.Item href={`/app/portal-settings`}> Portal settings</Dropdown.Item>} */}
              <Dropdown.Item href={`/app/portal-settings/reset-admin-password`}>
                {" "}
                Change Your Password
              </Dropdown.Item>
              {/* <Dropdown.Item href={`/app/attendance`}> Attendance</Dropdown.Item> */}
              {/* <Dropdown.Item href={`/app/accounts`}> Manage Account</Dropdown.Item> */}
              <Dropdown.Item
                onClick={() => {
                  resetReload();
                  logout();
                }}
              >
                {" "}
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  adminLoginRequest: state.allOnboardingReducers.adminLogin,
});

export default connect(mapStateToProps)(AppHeader);
