import { appConstantsPortal } from "../../action-constants/portal";

const initialState = {};

export const adminGetAStudentInvoiceReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.GET_STUDENT_INVOICE_PENDING:
      return {
        request_status: appConstantsPortal.GET_STUDENT_INVOICE_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_STUDENT_INVOICE_SUCCESS:
      return {
        request_status: appConstantsPortal.GET_STUDENT_INVOICE_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_STUDENT_INVOICE_FAILURE:
      return {
        request_status: appConstantsPortal.GET_STUDENT_INVOICE_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_STUDENT_INVOICE_RESET:
      return {
        request_status: appConstantsPortal.GET_STUDENT_INVOICE_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminUpdateAStudentInvoiceReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.UPDATE_STUDENT_INVOICE_PENDING:
      return {
        request_status: appConstantsPortal.UPDATE_STUDENT_INVOICE_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.UPDATE_STUDENT_INVOICE_SUCCESS:
      return {
        request_status: appConstantsPortal.UPDATE_STUDENT_INVOICE_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.UPDATE_STUDENT_INVOICE_FAILURE:
      return {
        request_status: appConstantsPortal.UPDATE_STUDENT_INVOICE_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.UPDATE_STUDENT_INVOICE_RESET:
      return {
        request_status: appConstantsPortal.UPDATE_STUDENT_INVOICE_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminLoadRegMetaReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case appConstantsPortal.FETCH_COURSE_REG_META_PENDING:
      return {
        request_status: appConstantsPortal.FETCH_COURSE_REG_META_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.FETCH_COURSE_REG_META_SUCCESS:
      return {
        request_status: appConstantsPortal.FETCH_COURSE_REG_META_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.FETCH_COURSE_REG_META_FAILURE:
      return {
        request_status: appConstantsPortal.FETCH_COURSE_REG_META_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.FETCH_COURSE_REG_META_RESET:
      return {
        request_status: appConstantsPortal.FETCH_COURSE_REG_META_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminLoadPresetCourseRegReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.LOAD_PRESET_COURSE_REG_PENDING:
      return {
        request_status: appConstantsPortal.LOAD_PRESET_COURSE_REG_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.LOAD_PRESET_COURSE_REG_SUCCESS:
      return {
        request_status: appConstantsPortal.LOAD_PRESET_COURSE_REG_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.LOAD_PRESET_COURSE_REG_FAILURE:
      return {
        request_status: appConstantsPortal.LOAD_PRESET_COURSE_REG_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.LOAD_PRESET_COURSE_REG_RESET:
      return {
        request_status: appConstantsPortal.LOAD_PRESET_COURSE_REG_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminSavePresetCourseRegReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.SAVE_PRESET_COURSE_REG_PENDING:
      return {
        request_status: appConstantsPortal.SAVE_PRESET_COURSE_REG_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.SAVE_PRESET_COURSE_REG_SUCCESS:
      return {
        request_status: appConstantsPortal.SAVE_PRESET_COURSE_REG_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.SAVE_PRESET_COURSE_REG_FAILURE:
      return {
        request_status: appConstantsPortal.SAVE_PRESET_COURSE_REG_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.SAVE_PRESET_COURSE_REG_RESET:
      return {
        request_status: appConstantsPortal.SAVE_PRESET_COURSE_REG_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminDeletePresetCourseRegReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.DELETE_PRESET_COURSE_REG_PENDING:
      return {
        request_status: appConstantsPortal.DELETE_PRESET_COURSE_REG_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.DELETE_PRESET_COURSE_REG_SUCCESS:
      return {
        request_status: appConstantsPortal.DELETE_PRESET_COURSE_REG_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.DELETE_PRESET_COURSE_REG_FAILURE:
      return {
        request_status: appConstantsPortal.DELETE_PRESET_COURSE_REG_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.DELETE_PRESET_COURSE_REG_RESET:
      return {
        request_status: appConstantsPortal.DELETE_PRESET_COURSE_REG_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminGetAllStudentsReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.GET_STUDENTS_LIST_PENDING:
      return {
        request_status: appConstantsPortal.GET_STUDENTS_LIST_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_STUDENTS_LIST_SUCCESS:
      return {
        request_status: appConstantsPortal.GET_STUDENTS_LIST_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_STUDENTS_LIST_FAILURE:
      return {
        request_status: appConstantsPortal.GET_STUDENTS_LIST_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_STUDENTS_LIST_RESET:
      return {
        request_status: appConstantsPortal.GET_STUDENTS_LIST_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminGetAStudentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case appConstantsPortal.GET_A_STUDENT_PENDING:
      return {
        request_status: appConstantsPortal.GET_A_STUDENT_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_A_STUDENT_SUCCESS:
      return {
        request_status: appConstantsPortal.GET_A_STUDENT_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_A_STUDENT_FAILURE:
      return {
        request_status: appConstantsPortal.GET_A_STUDENT_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_A_STUDENT_RESET:
      return {
        request_status: appConstantsPortal.GET_A_STUDENT_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminUpdateAStudentReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.UPDATE_A_STUDENT_PENDING:
      return {
        request_status: appConstantsPortal.UPDATE_A_STUDENT_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.UPDATE_A_STUDENT_SUCCESS:
      return {
        request_status: appConstantsPortal.UPDATE_A_STUDENT_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.UPDATE_A_STUDENT_FAILURE:
      return {
        request_status: appConstantsPortal.UPDATE_A_STUDENT_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.UPDATE_A_STUDENT_RESET:
      return {
        request_status: appConstantsPortal.UPDATE_A_STUDENT_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminUpdateAStudentCourseOfStudyReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.UPDATE_A_STUDENT_COURSE_OFSTUDY_PENDING:
      return {
        request_status:
          appConstantsPortal.UPDATE_A_STUDENT_COURSE_OFSTUDY_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.UPDATE_A_STUDENT_COURSE_OFSTUDY_SUCCESS:
      return {
        request_status:
          appConstantsPortal.UPDATE_A_STUDENT_COURSE_OFSTUDY_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.UPDATE_A_STUDENT_COURSE_OFSTUDY_FAILURE:
      return {
        request_status:
          appConstantsPortal.UPDATE_A_STUDENT_COURSE_OFSTUDY_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.UPDATE_A_STUDENT_COURSE_OFSTUDY_RESET:
      return {
        request_status:
          appConstantsPortal.UPDATE_A_STUDENT_COURSE_OFSTUDY_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminGetAStudentProgramChangeHistoryReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.GET_STUDENT_PROGRAMCHANGE_HISTORY_PENDING:
      return {
        request_status:
          appConstantsPortal.GET_STUDENT_PROGRAMCHANGE_HISTORY_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_STUDENT_PROGRAMCHANGE_HISTORY_SUCCESS:
      return {
        request_status:
          appConstantsPortal.GET_STUDENT_PROGRAMCHANGE_HISTORY_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_STUDENT_PROGRAMCHANGE_HISTORY_FAILURE:
      return {
        request_status:
          appConstantsPortal.GET_STUDENT_PROGRAMCHANGE_HISTORY_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_STUDENT_PROGRAMCHANGE_HISTORY_RESET:
      return {
        request_status:
          appConstantsPortal.GET_STUDENT_PROGRAMCHANGE_HISTORY_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminGetBioDataMetaReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.FETCH_BIODATA_META_PENDING:
      return {
        request_status: appConstantsPortal.FETCH_BIODATA_META_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.FETCH_BIODATA_META_SUCCESS:
      return {
        request_status: appConstantsPortal.FETCH_BIODATA_META_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.FETCH_BIODATA_META_FAILURE:
      return {
        request_status: appConstantsPortal.FETCH_BIODATA_META_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.FETCH_BIODATA_META_RESET:
      return {
        request_status: appConstantsPortal.FETCH_BIODATA_META_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminGetDashboardReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case appConstantsPortal.LOAD_DASHBOARD_PENDING:
      return {
        request_status: appConstantsPortal.LOAD_DASHBOARD_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.LOAD_DASHBOARD_SUCCESS:
      return {
        request_status: appConstantsPortal.LOAD_DASHBOARD_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.LOAD_DASHBOARD_FAILURE:
      return {
        request_status: appConstantsPortal.LOAD_DASHBOARD_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.LOAD_DASHBOARD_RESET:
      return {
        request_status: appConstantsPortal.LOAD_DASHBOARD_RESET,
        is_request_processing: false,
        request_data: {},
      };
      case appConstantsPortal.GET_SESSION_HISTORY:
      return {
        ...state,
        sessionHistory: action.payload
      };

    default:
      return { ...state };
  }
};

export const adminGetAwardsInViewReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.GET_AWARDS_IN_VIEW_PENDING:
      return {
        request_status: appConstantsPortal.GET_AWARDS_IN_VIEW_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_AWARDS_IN_VIEW_SUCCESS:
      return {
        request_status: appConstantsPortal.GET_AWARDS_IN_VIEW_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_AWARDS_IN_VIEW_FAILURE:
      return {
        request_status: appConstantsPortal.GET_AWARDS_IN_VIEW_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_AWARDS_IN_VIEW_RESET:
      return {
        request_status: appConstantsPortal.GET_AWARDS_IN_VIEW_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminAddDeleteAwardInViewReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.ADD_DELETE_AWARDS_IN_VIEW_PENDING:
      return {
        request_status: appConstantsPortal.ADD_DELETE_AWARDS_IN_VIEW_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.ADD_DELETE_AWARDS_IN_VIEW_SUCCESS:
      return {
        request_status: appConstantsPortal.ADD_DELETE_AWARDS_IN_VIEW_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.ADD_DELETE_AWARDS_IN_VIEW_FAILURE:
      return {
        request_status: appConstantsPortal.ADD_DELETE_AWARDS_IN_VIEW_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.ADD_DELETE_AWARDS_IN_VIEW_RESET:
      return {
        request_status: appConstantsPortal.ADD_DELETE_AWARDS_IN_VIEW_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminGetCountriesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case appConstantsPortal.GET_COUNTRIES_PENDING:
      return {
        request_status: appConstantsPortal.GET_COUNTRIES_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_COUNTRIES_SUCCESS:
      return {
        request_status: appConstantsPortal.GET_COUNTRIES_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_COUNTRIES_FAILURE:
      return {
        request_status: appConstantsPortal.GET_COUNTRIES_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_COUNTRIES_RESET:
      return {
        request_status: appConstantsPortal.GET_COUNTRIES_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminAddDeleteCountryReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.ADD_DELETE_COUNTRIES_PENDING:
      return {
        request_status: appConstantsPortal.ADD_DELETE_COUNTRIES_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.ADD_DELETE_COUNTRIES_SUCCESS:
      return {
        request_status: appConstantsPortal.ADD_DELETE_COUNTRIES_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.ADD_DELETE_COUNTRIES_FAILURE:
      return {
        request_status: appConstantsPortal.ADD_DELETE_COUNTRIES_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.ADD_DELETE_COUNTRIES_RESET:
      return {
        request_status: appConstantsPortal.ADD_DELETE_COUNTRIES_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminGetQualificationsReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.GET_HIGHEST_QUALIFICATIONS_PENDING:
      return {
        request_status: appConstantsPortal.GET_HIGHEST_QUALIFICATIONS_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_HIGHEST_QUALIFICATIONS_SUCCESS:
      return {
        request_status: appConstantsPortal.GET_HIGHEST_QUALIFICATIONS_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_HIGHEST_QUALIFICATIONS_FAILURE:
      return {
        request_status: appConstantsPortal.GET_HIGHEST_QUALIFICATIONS_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_HIGHEST_QUALIFICATIONS_RESET:
      return {
        request_status: appConstantsPortal.GET_HIGHEST_QUALIFICATIONS_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminAddDeleteQualificationsReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.ADD_DELETE_HIGHEST_QUALIFICATIONS_PENDING:
      return {
        request_status:
          appConstantsPortal.ADD_DELETE_HIGHEST_QUALIFICATIONS_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.ADD_DELETE_HIGHEST_QUALIFICATIONS_SUCCESS:
      return {
        request_status:
          appConstantsPortal.ADD_DELETE_HIGHEST_QUALIFICATIONS_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.ADD_DELETE_HIGHEST_QUALIFICATIONS_FAILURE:
      return {
        request_status:
          appConstantsPortal.ADD_DELETE_HIGHEST_QUALIFICATIONS_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.ADD_DELETE_HIGHEST_QUALIFICATIONS_RESET:
      return {
        request_status:
          appConstantsPortal.ADD_DELETE_HIGHEST_QUALIFICATIONS_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminGetSpecialitiesReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.GET_SPECIALITIES_PENDING:
      return {
        request_status: appConstantsPortal.GET_SPECIALITIES_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_SPECIALITIES_SUCCESS:
      return {
        request_status: appConstantsPortal.GET_SPECIALITIES_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_SPECIALITIES_FAILURE:
      return {
        request_status: appConstantsPortal.GET_SPECIALITIES_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_SPECIALITIES_RESET:
      return {
        request_status: appConstantsPortal.GET_SPECIALITIES_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminAddDeleteSpecialitiesReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.ADD_DELETE_SPECIALITIES_PENDING:
      return {
        request_status: appConstantsPortal.ADD_DELETE_SPECIALITIES_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.ADD_DELETE_SPECIALITIES_SUCCESS:
      return {
        request_status: appConstantsPortal.ADD_DELETE_SPECIALITIES_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.ADD_DELETE_SPECIALITIES_FAILURE:
      return {
        request_status: appConstantsPortal.ADD_DELETE_SPECIALITIES_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.ADD_DELETE_SPECIALITIES_RESET:
      return {
        request_status: appConstantsPortal.ADD_DELETE_SPECIALITIES_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminGetCurrentSessionReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.GET_CURRENT_SESSION_PENDING:
      return {
        request_status: appConstantsPortal.GET_CURRENT_SESSION_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_CURRENT_SESSION_SUCCESS:
      return {
        request_status: appConstantsPortal.GET_CURRENT_SESSION_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_CURRENT_SESSION_FAILURE:
      return {
        request_status: appConstantsPortal.GET_CURRENT_SESSION_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_CURRENT_SESSION_RESET:
      return {
        request_status: appConstantsPortal.GET_CURRENT_SESSION_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminSetCurrentSessionReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.SET_CURRENT_SESSION_PENDING:
      return {
        request_status: appConstantsPortal.SET_CURRENT_SESSION_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.SET_CURRENT_SESSION_SUCCESS:
      return {
        request_status: appConstantsPortal.SET_CURRENT_SESSION_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.SET_CURRENT_SESSION_FAILURE:
      return {
        request_status: appConstantsPortal.SET_CURRENT_SESSION_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.SET_CURRENT_SESSION_RESET:
      return {
        request_status: appConstantsPortal.SET_CURRENT_SESSION_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminResetPasswordReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.RESET_STUDENT_PASSWORD_PENDING:
      return {
        request_status: appConstantsPortal.RESET_STUDENT_PASSWORD_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.RESET_STUDENT_PASSWORD_SUCCESS:
      return {
        request_status: appConstantsPortal.RESET_STUDENT_PASSWORD_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.RESET_STUDENT_PASSWORD_FAILURE:
      return {
        request_status: appConstantsPortal.RESET_STUDENT_PASSWORD_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.RESET_STUDENT_PASSWORD_RESET:
      return {
        request_status: appConstantsPortal.RESET_STUDENT_PASSWORD_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminResetAdminReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case appConstantsPortal.RESET_ADMIN_PASSWORD_PENDING:
      return {
        request_status: appConstantsPortal.RESET_ADMIN_PASSWORD_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.RESET_ADMIN_PASSWORD_SUCCESS:
      return {
        request_status: appConstantsPortal.RESET_ADMIN_PASSWORD_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.RESET_ADMIN_PASSWORD_FAILURE:
      return {
        request_status: appConstantsPortal.RESET_ADMIN_PASSWORD_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.RESET_ADMIN_PASSWORD_RESET:
      return {
        request_status: appConstantsPortal.RESET_ADMIN_PASSWORD_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminUpdateStatusReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case appConstantsPortal.UPDATE_A_STUDENT_STATUS_PENDING:
      return {
        request_status: appConstantsPortal.UPDATE_A_STUDENT_STATUS_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.UPDATE_A_STUDENT_STATUS_SUCCESS:
      return {
        request_status: appConstantsPortal.UPDATE_A_STUDENT_STATUS_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.UPDATE_A_STUDENT_STATUS_FAILURE:
      return {
        request_status: appConstantsPortal.UPDATE_A_STUDENT_STATUS_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.UPDATE_A_STUDENT_STATUS_RESET:
      return {
        request_status: appConstantsPortal.UPDATE_A_STUDENT_STATUS_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminUpdateStudentOriginReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.UPDATE_A_STUDENT_ORIGIN_PENDING:
      return {
        request_status: appConstantsPortal.UPDATE_A_STUDENT_ORIGIN_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.UPDATE_A_STUDENT_ORIGIN_SUCCESS:
      return {
        request_status: appConstantsPortal.UPDATE_A_STUDENT_ORIGIN_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.UPDATE_A_STUDENT_ORIGIN_FAILURE:
      return {
        request_status: appConstantsPortal.UPDATE_A_STUDENT_ORIGIN_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.UPDATE_A_STUDENT_ORIGIN_RESET:
      return {
        request_status: appConstantsPortal.UPDATE_A_STUDENT_ORIGIN_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminCreateAUserReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case appConstantsPortal.CREATE_A_USER_PENDING:
      return {
        request_status: appConstantsPortal.CREATE_A_USER_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.CREATE_A_USER_SUCCESS:
      return {
        request_status: appConstantsPortal.CREATE_A_USER_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.CREATE_A_USER_FAILURE:
      return {
        request_status: appConstantsPortal.CREATE_A_USER_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.CREATE_A_USER_RESET:
      return {
        request_status: appConstantsPortal.CREATE_A_USER_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminUpdateAUserStatusReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.ACTIVATE_OR_DEACTIVATE_USER_PENDING:
      return {
        request_status: appConstantsPortal.ACTIVATE_OR_DEACTIVATE_USER_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.ACTIVATE_OR_DEACTIVATE_USER_SUCCESS:
      return {
        request_status: appConstantsPortal.ACTIVATE_OR_DEACTIVATE_USER_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.ACTIVATE_OR_DEACTIVATE_USER_FAILURE:
      return {
        request_status: appConstantsPortal.ACTIVATE_OR_DEACTIVATE_USER_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.ACTIVATE_OR_DEACTIVATE_USER_RESET:
      return {
        request_status: appConstantsPortal.ACTIVATE_OR_DEACTIVATE_USER_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminCreateARoleReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case appConstantsPortal.CREATE_A_ROLE_PENDING:
      return {
        request_status: appConstantsPortal.CREATE_A_ROLE_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.CREATE_A_ROLE_SUCCESS:
      return {
        request_status: appConstantsPortal.CREATE_A_ROLE_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.CREATE_A_ROLE_FAILURE:
      return {
        request_status: appConstantsPortal.CREATE_A_ROLE_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.CREATE_A_ROLE_RESET:
      return {
        request_status: appConstantsPortal.CREATE_A_ROLE_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminAddUserToARoleReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.UPDATE_A_USER_ROLE_PENDING:
      return {
        request_status: appConstantsPortal.UPDATE_A_USER_ROLE_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.UPDATE_A_USER_ROLE_SUCCESS:
      return {
        request_status: appConstantsPortal.UPDATE_A_USER_ROLE_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.UPDATE_A_USER_ROLE_FAILURE:
      return {
        request_status: appConstantsPortal.UPDATE_A_USER_ROLE_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.UPDATE_A_USER_ROLE_RESET:
      return {
        request_status: appConstantsPortal.UPDATE_A_USER_ROLE_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminAssignCourseAdvToDeptReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.ASSIGN_COURSE_ADVISOR_TO_DEPT_PENDING:
      return {
        request_status:
          appConstantsPortal.ASSIGN_COURSE_ADVISOR_TO_DEPT_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.ASSIGN_COURSE_ADVISOR_TO_DEPT_SUCCESS:
      return {
        request_status:
          appConstantsPortal.ASSIGN_COURSE_ADVISOR_TO_DEPT_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.ASSIGN_COURSE_ADVISOR_TO_DEPT_FAILURE:
      return {
        request_status:
          appConstantsPortal.ASSIGN_COURSE_ADVISOR_TO_DEPT_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.ASSIGN_COURSE_ADVISOR_TO_DEPT_RESET:
      return {
        request_status: appConstantsPortal.ASSIGN_COURSE_ADVISOR_TO_DEPT_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminGetAllApisReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case appConstantsPortal.GET_ALL_APIS_PENDING:
      return {
        request_status: appConstantsPortal.GET_ALL_APIS_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_ALL_APIS_SUCCESS:
      return {
        request_status: appConstantsPortal.GET_ALL_APIS_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_ALL_APIS_FAILURE:
      return {
        request_status: appConstantsPortal.GET_ALL_APIS_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_ALL_APIS_RESET:
      return {
        request_status: appConstantsPortal.GET_ALL_APIS_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminGetAllUserRolesReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.GET_ALL_USER_ROLES_PENDING:
      return {
        request_status: appConstantsPortal.GET_ALL_USER_ROLES_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_ALL_USER_ROLES_SUCCESS:
      return {
        request_status: appConstantsPortal.GET_ALL_USER_ROLES_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_ALL_USER_ROLES_FAILURE:
      return {
        request_status: appConstantsPortal.GET_ALL_USER_ROLES_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_ALL_USER_ROLES_RESET:
      return {
        request_status: appConstantsPortal.GET_ALL_USER_ROLES_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminGetAllUsersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case appConstantsPortal.GET_ALL_USERS_PENDING:
      return {
        request_status: appConstantsPortal.GET_ALL_USERS_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_ALL_USERS_SUCCESS:
      return {
        request_status: appConstantsPortal.GET_ALL_USERS_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_ALL_USERS_FAILURE:
      return {
        request_status: appConstantsPortal.GET_ALL_USERS_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_ALL_USERS_RESET:
      return {
        request_status: appConstantsPortal.GET_ALL_USERS_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminGetAUserReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case appConstantsPortal.GET_A_USER_PENDING:
      return {
        request_status: appConstantsPortal.GET_A_USER_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_A_USER_SUCCESS:
      return {
        request_status: appConstantsPortal.GET_A_USER_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_A_USER_FAILURE:
      return {
        request_status: appConstantsPortal.GET_A_USER_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_A_USER_RESET:
      return {
        request_status: appConstantsPortal.GET_A_USER_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminLoadNewUserMetaReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.NEW_USER_META_PENDING:
      return {
        request_status: appConstantsPortal.NEW_USER_META_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.NEW_USER_META_SUCCESS:
      return {
        request_status: appConstantsPortal.NEW_USER_META_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.NEW_USER_META_FAILURE:
      return {
        request_status: appConstantsPortal.NEW_USER_META_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.NEW_USER_META_RESET:
      return {
        request_status: appConstantsPortal.NEW_USER_META_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminGetSchoolFeesReportReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.GET_BURSARY_SCHOOLFEE_REPORTS_PENDING:
      return {
        request_status:
          appConstantsPortal.GET_BURSARY_SCHOOLFEE_REPORTS_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_BURSARY_SCHOOLFEE_REPORTS_SUCCESS:
      return {
        request_status:
          appConstantsPortal.GET_BURSARY_SCHOOLFEE_REPORTS_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_BURSARY_SCHOOLFEE_REPORTS_FAILURE:
      return {
        request_status:
          appConstantsPortal.GET_BURSARY_SCHOOLFEE_REPORTS_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_BURSARY_SCHOOLFEE_REPORTS_RESET:
      return {
        request_status: appConstantsPortal.GET_BURSARY_SCHOOLFEE_REPORTS_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminDownloadSchoolFeesReportReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.DOWNLOAD_BURSARY_SCHOOLFEE_REPORTS_PENDING:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_BURSARY_SCHOOLFEE_REPORTS_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_BURSARY_SCHOOLFEE_REPORTS_SUCCESS:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_BURSARY_SCHOOLFEE_REPORTS_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_BURSARY_SCHOOLFEE_REPORTS_FAILURE:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_BURSARY_SCHOOLFEE_REPORTS_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_BURSARY_SCHOOLFEE_REPORTS_RESET:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_BURSARY_SCHOOLFEE_REPORTS_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminGetAcceptanceFeesReportReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.GET_BURSARY_ACCEPTANCEFEE_REPORTS_PENDING:
      return {
        request_status:
          appConstantsPortal.GET_BURSARY_ACCEPTANCEFEE_REPORTS_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_BURSARY_ACCEPTANCEFEE_REPORTS_SUCCESS:
      return {
        request_status:
          appConstantsPortal.GET_BURSARY_ACCEPTANCEFEE_REPORTS_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_BURSARY_ACCEPTANCEFEE_REPORTS_FAILURE:
      return {
        request_status:
          appConstantsPortal.GET_BURSARY_ACCEPTANCEFEE_REPORTS_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_BURSARY_ACCEPTANCEFEE_REPORTS_RESET:
      return {
        request_status:
          appConstantsPortal.GET_BURSARY_ACCEPTANCEFEE_REPORTS_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminDownloadAcceptanceFeesReportReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.DOWNLOAD_BURSARY_ACCEPTANCEFEE_REPORTS_PENDING:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_BURSARY_ACCEPTANCEFEE_REPORTS_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_BURSARY_ACCEPTANCEFEE_REPORTS_SUCCESS:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_BURSARY_ACCEPTANCEFEE_REPORTS_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_BURSARY_ACCEPTANCEFEE_REPORTS_FAILURE:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_BURSARY_ACCEPTANCEFEE_REPORTS_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_BURSARY_ACCEPTANCEFEE_REPORTS_RESET:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_BURSARY_ACCEPTANCEFEE_REPORTS_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminGetAdditionalFeesReportReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.GET_BURSARY_ADDITIONALFEE_REPORTS_PENDING:
      return {
        request_status:
          appConstantsPortal.GET_BURSARY_ADDITIONALFEE_REPORTS_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_BURSARY_ADDITIONALFEE_REPORTS_SUCCESS:
      return {
        request_status:
          appConstantsPortal.GET_BURSARY_ADDITIONALFEE_REPORTS_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_BURSARY_ADDITIONALFEE_REPORTS_FAILURE:
      return {
        request_status:
          appConstantsPortal.GET_BURSARY_ADDITIONALFEE_REPORTS_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_BURSARY_ADDITIONALFEE_REPORTS_RESET:
      return {
        request_status:
          appConstantsPortal.GET_BURSARY_ADDITIONALFEE_REPORTS_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminDownloadAdditionalFeesReportReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_PENDING:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_SUCCESS:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_FAILURE:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_RESET:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_BURSARY_ADDITIONALFEE_REPORTS_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminGetMatriculationReportReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.GET_MATRICULATION_REPORTS_PENDING:
      return {
        request_status: appConstantsPortal.GET_MATRICULATION_REPORTS_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_MATRICULATION_REPORTS_SUCCESS:
      return {
        request_status: appConstantsPortal.GET_MATRICULATION_REPORTS_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_MATRICULATION_REPORTS_FAILURE:
      return {
        request_status: appConstantsPortal.GET_MATRICULATION_REPORTS_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_MATRICULATION_REPORTS_RESET:
      return {
        request_status: appConstantsPortal.GET_MATRICULATION_REPORTS_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminDownloadMatriculationReportsReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.DOWNLOAD_MATRICULATION_REPORT_PENDING:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_MATRICULATION_REPORT_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_MATRICULATION_REPORT_SUCCESS:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_MATRICULATION_REPORT_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_MATRICULATION_REPORT_FAILURE:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_MATRICULATION_REPORT_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_MATRICULATION_REPORT_RESET:
      return {
        request_status: appConstantsPortal.DOWNLOAD_MATRICULATION_REPORT_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminGetNewStudentsPaymentsReportReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.GET_NEW_STUDENTS_PAYMENTS_REPORTS_PENDING:
      return {
        request_status:
          appConstantsPortal.GET_NEW_STUDENTS_PAYMENTS_REPORTS_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_NEW_STUDENTS_PAYMENTS_REPORTS_SUCCESS:
      return {
        request_status:
          appConstantsPortal.GET_NEW_STUDENTS_PAYMENTS_REPORTS_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_NEW_STUDENTS_PAYMENTS_REPORTS_FAILURE:
      return {
        request_status:
          appConstantsPortal.GET_NEW_STUDENTS_PAYMENTS_REPORTS_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_NEW_STUDENTS_PAYMENTS_REPORTS_RESET:
      return {
        request_status:
          appConstantsPortal.GET_NEW_STUDENTS_PAYMENTS_REPORTS_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminDownloadNewStudentsPaymentsReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_PENDING:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_SUCCESS:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_FAILURE:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_RESET:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_NEW_STUDENTS_PAYMENTS_REPORTS_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminGetStudentsRegistrationReportReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.GET_STUDENTS_REGISTRATION_REPORTS_PENDING:
      return {
        request_status:
          appConstantsPortal.GET_STUDENTS_REGISTRATION_REPORTS_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_STUDENTS_REGISTRATION_REPORTS_SUCCESS:
      return {
        request_status:
          appConstantsPortal.GET_STUDENTS_REGISTRATION_REPORTS_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_STUDENTS_REGISTRATION_REPORTS_FAILURE:
      return {
        request_status:
          appConstantsPortal.GET_STUDENTS_REGISTRATION_REPORTS_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_STUDENTS_REGISTRATION_REPORTS_RESET:
      return {
        request_status:
          appConstantsPortal.GET_STUDENTS_REGISTRATION_REPORTS_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminDownloadStudentsRegistrationReportReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.DOWNLOAD_STUDENTS_REGISTRATION_REPORT_PENDING:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_STUDENTS_REGISTRATION_REPORT_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_STUDENTS_REGISTRATION_REPORT_SUCCESS:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_STUDENTS_REGISTRATION_REPORT_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_STUDENTS_REGISTRATION_REPORT_FAILURE:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_STUDENTS_REGISTRATION_REPORT_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_STUDENTS_REGISTRATION_REPORT_RESET:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_STUDENTS_REGISTRATION_REPORT_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};

export const adminGetNelFundReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case appConstantsPortal.GET_NELFUND_REPORTS_PENDING:
      return {
        request_status: appConstantsPortal.GET_NELFUND_REPORTS_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_NELFUND_REPORTS_SUCCESS:
      return {
        request_status: appConstantsPortal.GET_NELFUND_REPORTS_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_NELFUND_REPORTS_FAILURE:
      return {
        request_status: appConstantsPortal.GET_NELFUND_REPORTS_FAILURE,
        is_request_processing: false,
        request_data: action,
      };

    default:
      return { ...state };
  }
};

export const adminGetNelFundDownloadReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.GET_NELFUND_DOWNLOAD_REPORTS_PENDING:
      return {
        request_status: appConstantsPortal.GET_NELFUND_DOWNLOAD_REPORTS_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_NELFUND_DOWNLOAD_REPORTS_SUCCESS:
      return {
        request_status: appConstantsPortal.GET_NELFUND_DOWNLOAD_REPORTS_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_NELFUND_DOWNLOAD_REPORTS_FAILURE:
      return {
        request_status: appConstantsPortal.GET_NELFUND_DOWNLOAD_REPORTS_FAILURE,
        is_request_processing: false,
        request_data: action,
      };

    case appConstantsPortal.GET_NELFUND_DOWNLOAD_REPORTS_RESET:
      return {
        request_status:
          appConstantsPortal.GET_NELFUND_DOWNLOAD_REPORTS_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};


export const printBioDataReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.PRINT_BIODATA_SUCCESS:
      return {
        request_status: appConstantsPortal.PRINT_BIODATA_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.PRINT_BIODATA_FAILURE:
      return {
        request_status: appConstantsPortal.PRINT_BIODATA_FAILURE,
        is_request_processing: false,
        request_data: action,
      };

    default:
      return { ...state };
  }
};


export const adminGetEORegistrationReportReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.GET_EO_REGISTRATION_REPORTS_PENDING:
      return {
        request_status:
          appConstantsPortal.GET_EO_REGISTRATION_REPORTS_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.GET_EO_REGISTRATION_REPORTS_SUCCESS:
      return {
        request_status:
          appConstantsPortal.GET_EO_REGISTRATION_REPORTS_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.GET_EO_REGISTRATION_REPORTS_FAILURE:
      return {
        request_status:
          appConstantsPortal.GET_EO_REGISTRATION_REPORTS_FAILURE,
        is_request_processing: false,
        request_data: action,
      };

    default:
      return { ...state };
  }
};


export const adminDownloadEORegistrationReportReducer = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case appConstantsPortal.DOWNLOAD_EO_REGISTRATION_REPORTS_PENDING:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_EO_REGISTRATION_REPORTS_PENDING,
        is_request_processing: true,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_EO_REGISTRATION_REPORTS_SUCCESS:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_EO_REGISTRATION_REPORTS_SUCCESS,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_EO_REGISTRATION_REPORTS_FAILURE:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_EO_REGISTRATION_REPORTS_FAILURE,
        is_request_processing: false,
        request_data: action,
      };
    case appConstantsPortal.DOWNLOAD_EO_REGISTRATION_REPORTS_RESET:
      return {
        request_status:
          appConstantsPortal.DOWNLOAD_EO_REGISTRATION_REPORTS_RESET,
        is_request_processing: false,
        request_data: {},
      };

    default:
      return { ...state };
  }
};