import { Link } from "react-router-dom";

interface PortalSetttingsOption {
  name: string;
  linkTo: string;
  buttonText: string;
  allowedRoles: string[];
}

const PORTAL_SETTINGS_MODULE_LIST = [
  {
    name: "Highest Qualifications",
    linkTo: "/app/portal-settings/qualification",
    buttonText: "Update",
    allowedRoles: ["Admin"],
  },
  {
    name: "Awards-in-view",
    linkTo: "/app/portal-settings/awards",
    buttonText: "Update",
    allowedRoles: ["Admin"],
  },
  {
    name: "Country Settings",
    linkTo: "/app/portal-settings/countries",
    buttonText: "Update",
    allowedRoles: ["Admin"],
  },
  {
    name: "School Session Settings",
    linkTo: "/app/portal-settings/current-session",
    buttonText: "Update",
    allowedRoles: ["Admin"],
  },
  {
    name: "Specialization Settings",
    linkTo: "/app/portal-settings/specialization",
    buttonText: "Update",
    allowedRoles: ["Admin"],
  },
  {
    name: "Update Student Invoice",
    linkTo: "/app/portal-settings/update-student-invoice",
    buttonText: "Update",
    allowedRoles: ["Admin"],
  },
  {
    name: "Delete Student Invoice",
    linkTo: "/app/portal-settings/delete-student-invoice",
    buttonText: "Update",
    allowedRoles: ["Admin"],
  },
  {
    name: "Change Admitted Student Course",
    linkTo: "/app/portal-settings/update-student-course-of-study",
    buttonText: "Update",
    allowedRoles: ["Admin"],
  },
  {
    name: "Check Matric No. History",
    linkTo: "/app/portal-settings/student-course-of-study-change-history",
    buttonText: "Update",
    allowedRoles: ["Admin"],
  },
  {
    name: "Update Student State of Origin",
    linkTo: "/app/portal-settings/update-student-origin",
    buttonText: "Update",
    allowedRoles: ["Admin"],
  },
  {
    name: "Assign Dept. To Course Advisor",
    linkTo: "/app/portal-settings/assign-dept-to-course-advisor",
    buttonText: "Update",
    allowedRoles: ["Admin"],
  },
  {
    name: "Role Management settings",
    linkTo: "/app/portal-settings/manage-roles",
    buttonText: "Update",
    allowedRoles: ["Admin"],
  },
  {
    name: "User Management settings",
    linkTo: "/app/portal-settings/manage-users",
    buttonText: "Update",
    allowedRoles: ["Admin"],
  },
  {
    name: "Student Password Reset",
    linkTo: "/app/portal-settings/reset-student-password",
    buttonText: "Update",
    allowedRoles: ["Admin", "Password_Resetter"],
  },
  {
    name: "Activate/Deactivate Student",
    linkTo: "/app/portal-settings/update-student-status",
    buttonText: "Update",
    allowedRoles: ["Admin", "Admission_Manager", "Student_Manager"],
  },
  {
    name: "Update your Account Password",
    linkTo: "/app/portal-settings/reset-admin-password",
    buttonText: "Update",
    allowedRoles: ["all"],
  },
  {
    name: "Reset Other Users Password",
    linkTo: "/app/portal-settings/reset-a-user-password",
    buttonText: "Update",
    allowedRoles: ["Admin"],
  },
  {
    name: "Student Management",
    linkTo: "/app/portal-settings/student-management",
    buttonText: "Update",
    allowedRoles: ["Admin", "Student_Creator"],
  },
  {
    name: "Additional Payment",
    linkTo: "/app/portal-settings/additional-payment",
    buttonText: "Additional fees",
    allowedRoles: ["Admin", "Student_Manager"],
  },
];

const PortalSettingsCard = () => {
  let loggedAccountInfo: any = localStorage.getItem("adXAuth") || "{}";
  loggedAccountInfo = JSON.parse(loggedAccountInfo);
  const userRoles = loggedAccountInfo?.profile?.userRoles;

  return (
    <>
      {PORTAL_SETTINGS_MODULE_LIST.map((settings: PortalSetttingsOption) => (
        <>
          {settings?.allowedRoles?.length &&
          userRoles.some(
            (role: string) =>
              settings?.allowedRoles?.includes(role) ||
              settings?.allowedRoles[0] === "all"
          ) ? (
            <div className="each_setting">
              <div className="setting_name">{settings.name}</div>
              <div className="setting_link">
                <Link className="btn" to={settings.linkTo}>
                  {settings.buttonText}
                </Link>{" "}
              </div>
            </div>
          ) : null}
        </>
      ))}
    </>
  );
};

export default PortalSettingsCard;
