import React from 'react'
import Navigation from '../../../shared/components/navigation/Navigation'
import { Link } from 'react-router-dom'


const ReportMenu= () => {

  const loggedInUser: any = localStorage.getItem("adXAuth") || "{}";

  let user: any = JSON.parse(loggedInUser) || {};
  const userRole = user?.profile?.userRoles;

  return (
    <div className="putme-header">
    <div className="back" style={{marginLeft: "25px"}}>
      <Navigation to="/app/hostel-dashboard" text="Back to Hostel Dasbhoard" />
    </div>

    <div className="settings_list">
      <div className="each_setting">
        <div className="setting_name">&nbsp;Search Matric</div>
        <div className="setting_link">
          <Link className="btn" to="/app/hostels/report/search-matric">
          Search Report
          </Link>{" "}
        </div>
      </div>

      <div className="each_setting">
        <div className="setting_name"> &nbsp;Search Room</div>
        <div className="setting_link">
          <Link className="btn" to="/app/hostels/report/search-room">
            Search Report
          </Link>{" "}
        </div>
      </div>

      {! (userRole[0] === "Hostel_Porter") && 
      ( <div className="each_setting">
        <div className="setting_name">Reports</div>
        <div className="setting_link">
          <Link className="btn" to="/app/hostels/report-download">
            Generate Reports
          </Link>{" "}
        </div>
      </div>)}
      
    </div>
  </div>
  )
}

export default ReportMenu
