import { useState } from "react";
import { Formik, Form, FormikProps} from "formik";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import "./index.scss";
import DropDown from "../../../shared/components/dropdown";
import { PASSFEED_PROGRAMME_TYPES } from "../../../shared/_helpers/constants";
import { Button } from "react-bootstrap";
import { DotLoader } from "react-spinners";
import { toast } from "react-toastify";
import Navigation from "../../../shared/components/navigation/Navigation";
import DownloadPassfeedWithCardModal from "./downloadPassfeedWithCard";
import { getPassFeedWithScratchCardReports } from "../../../redux/action/putme";

const PassFeedWithCardReport = () => {
  const [show, setShow] = useState<boolean>(false);
  const [selectedProgrammeType, setSelectedProgrammeType] =
    useState<string>("");
  const [downloadUrl, setDownloadUrl] = useState("");
  const [loading, setLoading] = useState<boolean>(false);

  const initialValues = {
    programmeType: "",
  };
  const validationSchema = Yup.object().shape({
    programmeType: Yup.string().required("Required"),
  });


const PassFeedWithCardProgrammeType = PASSFEED_PROGRAMME_TYPES.filter(item => item.value !== "DE - ALEVEL")

  const fileType = "Excel";


  return (
    <div className="report-container">
      <div className="back">
        <Navigation to="/app/putme-report-cards" text="Back to Reports" />
      </div>
      <div className="report-page">
       {( selectedProgrammeType === "UTME" ||  selectedProgrammeType === "DE - OLEVEL") 
       && <DownloadPassfeedWithCardModal
          show={show}
          setShow={setShow}
          downloadUrl={downloadUrl}
          programmeType={selectedProgrammeType}
        /> }
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            setLoading(true);
            const { programmeType } = values;
            const response: any = await getPassFeedWithScratchCardReports(programmeType);
            if (response?.length || response?.hasData) {
              setDownloadUrl(response);
              setShow(true);
            } else if (
              (response && response?.length < 1) ||
              !response.hasData
            ) {
              toast.info("No record found");
            }
            setLoading(false);
          }}
        >
          {(props: FormikProps<any>) => {
            const {
              values,
              touched,
              errors,
              // handleBlur,
              handleChange,
              setFieldTouched,
              setFieldValue,
              // isSubmitting,
            } = props;
            return (
              <Form>
                <div className="form-container">
                  <div className="row">
                    <div className="form-group">
                      <div>
                        <DropDown
                          label="Programme type"
                          name="programmeType"
                          touched={touched}
                          errors={errors}
                          options={PassFeedWithCardProgrammeType}
                          field="programmeType"
                          width="100%"
                          value={{
                            value: values.programmeType,
                            label: values.programmeType,
                          }}
                          handleChange={(value: any) => {
                            setSelectedProgrammeType(value);
                            handleChange(value);
                          }}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="report-submit-btn">
                    <Button
                      disabled={loading}
                      type="submit"
                      variant="success"
                      className="download_cta"
                    >
                      {loading ? (
                        <DotLoader
                          color="white"
                          loading={loading}
                          size={30}
                          aria-label="Submitting"
                        />
                      ) : (
                        `Download as ${fileType}`
                      )}
                    </Button>
                  </div>
                </div>

              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default PassFeedWithCardReport;
