import { connect } from "react-redux";
import { useEffect } from "react";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { AlertMsg } from "../../shared/components/alert-msg";
import { adminInAppActions } from "../../redux/action/portal";
import { appConstantsPortal } from "../../redux/action-constants/portal";
import { InAppTemplate } from "../../shared/templates/portal";
import "./index.scss";
import Navigation from "../../shared/components/navigation/Navigation";

const adminResetStudentPassswordActionRequest = async ({
  pageProps,
  payload,
}: any) => {
  await pageProps.adminResetStudentPassswordAction(payload, true);
};

const StudentForm = ({ pageProps }: any) => {
  let resetRequestInfo = pageProps.adminResetPasswordRequest;

  let checkValidationSchema = Yup.object().shape({
    emailAddress: Yup.string()
      .email("Valid email required")
      .required("Required"),
  });
  let initialValues: any = { emailAddress: "" };

  return (
    <div className="student_form">
      <Formik
        initialValues={initialValues}
        validationSchema={checkValidationSchema}
        onSubmit={async (values) => {
          let { emailAddress } = values;
          let payload: any = {
            email: emailAddress,
          };

          await adminResetStudentPassswordActionRequest({ pageProps, payload });
        }}
      >
        {(props: FormikProps<any>) => {
          const {
            values,
            touched,
            errors,
            // handleBlur,
            handleChange,
            // isSubmitting,
          } = props;
          return (
            <Form>
              {resetRequestInfo.request_status !==
                appConstantsPortal?.RESET_STUDENT_PASSWORD_SUCCESS && (
                <>
                  <div className="form-group">
                    <label className="label">User Email Address </label>
                    <div
                      className={
                        errors.emailAddress && touched.emailAddress
                          ? "invalid-input-wrap"
                          : ""
                      }
                    >
                      <Field
                        value={values.emailAddress}
                        id="emailAddress"
                        name="emailAddress"
                        type="text"
                        disabled={resetRequestInfo?.is_request_processing}
                        onChange={handleChange("emailAddress")}
                        placeholder="Enter email"
                        className={`input-wrap ${
                          errors.emailAddress && touched.emailAddress
                            ? "is-invalid-input "
                            : !errors.emailAddress && values.emailAddress !== ""
                            ? "valid-input"
                            : ""
                        }`}
                      />
                    </div>
                    <ErrorMessage
                      name="emailAddress"
                      className="form-input-error"
                      component="div"
                    />
                  </div>

                  {resetRequestInfo.request_status ===
                    appConstantsPortal?.RESET_STUDENT_PASSWORD_FAILURE && (
                    <AlertMsg
                      type="error"
                      message={resetRequestInfo?.request_data.error}
                    />
                  )}
                  <div className="submit_cta">
                    <button
                      disabled={resetRequestInfo?.is_request_processing}
                      className="btn shaded"
                      type="submit"
                    >
                      {resetRequestInfo?.is_request_processing
                        ? "Please wait..."
                        : "Reset Password"}
                    </button>
                  </div>
                </>
              )}

              <div className="">
                {resetRequestInfo.request_status ===
                  appConstantsPortal?.RESET_STUDENT_PASSWORD_SUCCESS && (
                  <AlertMsg
                    type="success"
                    message={`Password has been  successfully reset for ${values?.emailAddress}`}
                  />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const UserPasswordResetSettings = ({ pageProps }: any) => {
  useEffect(() => {
    adminResetStudentPassswordActionRequest({
      pageProps,
      payload: "CLEAR",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="student_password settings_wrap_content">
       <div className="back-to-portal">
      <Navigation to="/app/portal-settings" text = "Back to Portal Settings"/>
      </div>
      <StudentForm pageProps={pageProps} />

      {/* {requestInfo.request_status === appConstantsPortal?.GET_A_STUDENT_SUCCESS &&
                <ResetPassword pageProps={pageProps} />
            } */}
    </div>
  );
};

const AdminUserPasswordSettingsWrap = (pageProps: any) => {
  return (
    <InAppTemplate
      pageTitle={`Reset User Password`}
      childComponent={<UserPasswordResetSettings pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  adminGetAStudentAction: adminInAppActions.AdminGetAStudent,
  adminResetStudentPassswordAction:
    adminInAppActions.AdminResetStudentPasssword,
};
const mapStateToProps = (state: any) => ({
  adminGetAStudentRequest: state.allUGradPortalReducers.adminGetAStudentReducer,
  adminResetPasswordRequest:
    state.allUGradPortalReducers.adminResetPasswordReducer,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminUserPasswordSettingsWrap);
