import GraduationCap from "../../assets/Graduation-Cap.png";
import Report from "../../assets/Graph-Report-Script.png";
import Settings from "../../assets/Settings.png";
import Dashboard from "../../assets/white-dashboard.png";
import Hostel from '../../assets/Bunk Bed.png'

export const APP_MENU = {
  LOGOUT: "/",
  DASHBOARD: "/app",
  ADMISSION_MANAGEMENT: "/app/admissions-management",
  COURSES: "/app/course-management/undergraduate",
  STUDENTS: "/app/students",
  PORTAL_SETTINGS: "/app/portal-settings",
  RESET_PASSWORD: "/app/portal-settings/reset-student-password",
  POST_UTME: "/app/postutme",
  POST_UTME_STUDENTS: "/app/postutme/students",
  POST_UTME_REPORTS: "/app/putme-report-cards",
  POST_UTME_SETTINGS: "/app/putme-settings/current-session",

  COURSE_SUBJECT: "/app/course-subject",
  E_DOCUMENTATION: "/app/e-documentation",
  E_DOCUMENTATION_DASHBOARD: "/app/e-documentation/dashboard",
  E_DOCUMENTATION_CLEAR_STUDENTS: "/app/e-documentation/review",
  E_DOCUMENTATION_REPORTS: "/app/e-documentation/reports",
  BURSARY_REPORTS: "/app/bursary-reports",
  REPORTS: "/app/reports",
  EO_REPORT: "/app/eo-reports",
  HOSTEL_MANAGEMENT:"/app/hostel-dashboard",
  HOSTEL_MANAGEMENT_REPORTS:"/app/hostels/reports",
  HOSTEL_MANAGEMENT_SETTINGS:"/app/hostel/settings",
  HOSTEL_CONFIGURATION:"/app/hostels/settings",
  HOSTEL:"/app/hostels"
};



export const POST_UTME_MENU_LIST = [
  {
    title: "STUDENT MANAGEMENT",
    buttonText: "Manage Students",
    icon: GraduationCap,
    backgroundColor: "#673BB7",
    routeTo: APP_MENU.POST_UTME_STUDENTS,
  },
  {
    title: "REPORTS",
    buttonText: "Generate and Print report",
    icon: Report,
    backgroundColor: "#002FA3",
    routeTo: APP_MENU.POST_UTME_REPORTS,
  },
  {
    title: "SETTINGS",
    buttonText: "Settings",
    icon: Settings,
    backgroundColor: "#000F34",
    routeTo: APP_MENU.POST_UTME_SETTINGS,
  },
];

export const HOSTEL_ACCOMMODATION_LIST = [
  {
    title: "ACCOMMODATIONS",
    buttonText: "Manage Hostel",
    icon: Hostel,
    backgroundColor: "#673BB7",
    routeTo: APP_MENU.HOSTEL,
  },
 
  {
    title: "SETTINGS",
    buttonText: "Settings",
    icon: Settings,
    backgroundColor: "#002FA3",
    routeTo: APP_MENU.HOSTEL_CONFIGURATION,
  },
  {
    title: "REPORTS",
    buttonText: "Reports",
    icon: Report,
    backgroundColor: "#000F34",
    routeTo: APP_MENU.HOSTEL_MANAGEMENT_REPORTS,
  },
];

export const postUtmeTabs = [
  {
      title: "TOTAL POST-UTME STUDENTS ",
      backgroundColor: "#673BB7",
      data: "totalPostUtmeStudents"
  },
  {
      title: "TOTAL POST-UTME SUBMITTED ",
      backgroundColor: "#633BB7",
      data: "totalPostUtmeSubmitted",
  },
  {
      title: "TOTAL POST-UTME NOT SUBMITTED",
      backgroundColor: "#673BB7",
      data: "totalPostUtmeNotSubmitted"
  },
  {
      title: "TOTAL INVOICES GENERATED",
      backgroundColor: "#673BB6",
      data: "totalPostUtmeInvoice"
  },
  {
      title: "TOTAL POST-UTME PAID",
      backgroundColor: "#673BB7",
      data: "totalPostUtmePaid"
  },
  {
      title: "TOTAL POST-UTME NOT PAID",
      backgroundColor: "#673BB7",
      data: "totalPostUtmeNotPaid"
  },
]


export const TotalHostelStatisticsTabs = [
  {
    title: "TOTAL INVOICES GENERATED ",
    backgroundColor: "#03A9F4",
    data: "totalInvoice"
},
{
    title: "TOTAL INVOICES PAID ",
    backgroundColor:  "#000F34",
    data: "totalPaidInvoices"
},
{
    title: "TOTAL INVOICES PENDING ",
    backgroundColor: "#FF5722",
    data: "totalPendingInvoices"
},
  ]


export const E_DOCUMENTATION_MENU_LIST = [
  {
    title: "DASHBOARD",
    buttonText: "Click",
    icon: Dashboard,
    backgroundColor: "#B33030",
    routeTo: APP_MENU.E_DOCUMENTATION_DASHBOARD,
  },
  {
    title: "CLEAR STUDENTS",
    buttonText: "Click",
    icon: GraduationCap,
    backgroundColor: "#068FFF",
    routeTo: APP_MENU.E_DOCUMENTATION_CLEAR_STUDENTS,
  },
  {
    title: "REPORT",
    buttonText: "Click",
    icon: Report,
    backgroundColor: "#FC5404",
  },
];

export const PROGRAMME_TYPES = [
  {
    label: "UTME",
    value: "UTME",
  },
  {
    label: "DE",
    value: "DE",
  }
];

export const PASSFEED_PROGRAMME_TYPES = [
  {
    label: "UTME",
    value: "UTME",
  },
  {
    label: "DE - ALEVEL",
    value: "DE - ALEVEL",
  },
  {
    label: "DE - OLEVEL",
    value: "DE - OLEVEL",
  }
];

export const subjects = [
  { value: "Accounting", label: "Accounting" },
  { value: "Agricultural Science", label: "Agricultural Science" },
  { value: "Animal Husbandry", label: "Animal Husbandry" },
  { value: "Arabic Language", label: "Arabic Language" },
  { value: "Basic Electricity", label: "Basic Electricity" },
  { value: "Biology", label: "Biology" },
  { value: "Book Keeping", label: "Book Keeping" },
  {
    value: "Brick Laying and Block Laying",
    label: "Brick Laying and Block Laying",
  },
  {
    value: "Building And Engineering Drawing",
    label: "Building And Engineering Drawing",
  },
  {
    value: "Cable Joint And Battery Charge",
    label: "Cable Joint And Battery Charge",
  },
  { value: "Chemistry", label: "Chemistry" },
  { value: "Civic Education", label: "Civic Education" },
  { value: "Class Teaching", label: "Class Teaching" },
  { value: "Commerce", label: "Commerce" },
  { value: "Computer Studies", label: "Computer Studies" },
  { value: "Concreating", label: "Concreating" },
  { value: "CRK", label: "CRK" },
  {
    value: "Domestic And Industrial Installation",
    label: "Domestic And Industrial Installation",
  },
  { value: "Economics", label: "Economics" },
  { value: "Education", label: "Education" },
  {
    value: "Electrical Installation and maintenance",
    label: "Electrical Installation and maintenance",
  },
  {
    value: "Electronic Device and Circuit",
    label: "Electronic Device and Circuit",
  },
  { value: "English", label: "English" },
  { value: "English Literature", label: "English Literature" },
  { value: "Fine Arts", label: "Fine Arts" },
  { value: "Fishery", label: "Fishery" },
  {
    value: "Fitting, Drilling and Grinding",
    label: "Fitting, Drilling and Grinding",
  },
  { value: "Food And Nutrition", label: "Food And Nutrition" },
  { value: "French", label: "French" },
  { value: "Further Mathematics", label: "Further Mathematics" },
  { value: "General Metal Work", label: "General Metal Work" },
  { value: "Geography", label: "Geography" },
  { value: "Government", label: "Government" },
  { value: "Hausa Language", label: "Hausa Language" },
  { value: "Hausa Literature", label: "Hausa Literature" },
  { value: "Health Science", label: "Health Science" },
  { value: "History", label: "History" },
  { value: "Home Economics", label: "Home Economics" },
  { value: "Home Management", label: "Home Management" },
  { value: "Igbo Language", label: "Igbo Language" },
  {
    value: "Information And Communication Tech.",
    label: "Information And Communication Tech.",
  },
  { value: "Int Science", label: "Int Science" },
  {
    value: "Intro to Building Construction",
    label: "Intro to Building Construction",
  },
  { value: "IRK", label: "IRK" },
  { value: "Marketing", label: "Marketing" },
  { value: "Mathematics", label: "Mathematics" },
  {
    value: "Mechanical Engineering Craft Practice",
    label: "Mechanical Engineering Craft Practice",
  },
  { value: "Motor Vehicle M. Works", label: "Motor Vehicle M. Works" },
  { value: "Music", label: "Music" },
  { value: "Office Practice", label: "Office Practice" },
  { value: "P.H.E.", label: "P.H.E." },
  { value: "Physics", label: "Physics" },
  { value: "Radio Communication", label: "Radio Communication" },
  { value: "Short Hand", label: "Short Hand" },
  { value: "Social Studies", label: "Social Studies" },
  { value: "Technical Drawing", label: "Technical Drawing" },
  { value: "Television", label: "Television" },
  {
    value: "Turning, Milling, Shaping, Planning, and Slotting",
    label: "Turning, Milling, Shaping, Planning, and Slotting",
  },
  { value: "Typewriting", label: "Typewriting" },
  { value: "Visual Arts", label: "Visual Arts" },
  {
    value: "Walls, Floors and Ceiling Finishing",
    label: "Walls, Floors and Ceiling Finishing",
  },
  {
    value: "Winding Of Electrical Machine",
    label: "Winding Of Electrical Machine",
  },
  { value: "Wood Work", label: "Wood Work" },
  { value: "Yoruba Language", label: "Yoruba Language" },
];

export const admissionSampleData = [
  [
    "RegNumb",
    "CandName",
    "Sex",
    "TOTALSCORE",
    "SESSION",
    "SignDate",
    "NATIONALITY",
    "State",
    "LGA",
    "FACULTY",
    "Dept",
    "Course",
    "MATNOFORMATION",
    "LevelOfEntry",
    "ProceedStatus",
    "ProceedStatusDetails",
  ],
  [
    "202210289471IFU",
    "Hussaini Maimuna",
    "F",
    "DE",
    "2021/2022",
    "OCTOBER",
    "NIGERIAN",
    "NIG",
    "AGAIE",
    "NATURAL",
    "BIOTECH",
    "BIOTECHNOLOGY",
    "U21/FNS/BTG/",
    "200",
    1,
    "VISIT ICT FOR RESULT VERIFICATION ON OR BEFORE 27-JAN-2023",
  ],
  [
    "202290092308FF",
    "MOHAMMED HAMZA",
    "F",
    "DE",
    "2021/2022",
    "OCTOBER",
    "NIGERIAN",
    "NIG",
    "AGAIE",
    "NATURAL",
    "BIOTECH",
    "BIOTECHNOLOGY",
    "U21/FNS/BTG/",
    "200",
    1,
    `VISIT ANY JAMB CBT CENTER OR IBBU CBT CENTER TO UPLOAD YOUR O'LEVEL RESULT ON JAMB CAPS`,
  ],
];

export const courseUploadSampleData = [
  ["COURSECODE", "COURSEUNIT", "COURSETITLE", "LEVEL"],
  ["PHY 101", 3, "MECHANICS, THERMAL PHYSICS AND WAVES", 1],
  ["PHY 102", 3, "ELECTRICITY, MAGNETISM AND MODERN PHYSICS", 1],
];

export const postUtmeUploadSampleData = [
  [
    "RG_NUM",
    "RG_CANDNAME",
    "RG_SEX",
    "STATE_NAME",
    "RG_AGGREGATE",
    "CO_NAME",
    "LGA_NAME",
    "Subject1",
    "RG_Sub1Score",
    "Subject2",
    "RG_Sub2Score",
    "Subject3",
    "RG_Sub3Score",
    "EngScore",
    "Subject4",
  ],
  [
    "20233104224556",
    "Khadija Fati Alhaji",
    "F",
    "NIG",
    165,
    "Computer Science",
    "BIDA",
    "ECO",
    41,
    "P_A",
    42,
    "COM",
    47,
    35,
    "USE OF ENGLISH",
  ],
  [
    "20233104224556",
    "Khadija Fati Alhaji",
    "F",
    "NIG",
    165,
    "Computer Science",
    "BIDA",
    "ECO",
    41,
    "P_A",
    42,
    "COM",
    47,
    35,
    "USE OF ENGLISH",
  ],
];

export const postDEUploadSampleData = [
  [
    "RG_NUM",
    "RG_CANDNAME",
    "RG_SEX",
    "STATENAME",
    "CO_NAME",
    "LGA",
    "IsDECleared"
  ],
  [
    "20233104224556",
    "Khadija Fati Alhaji",
    "F",
    "NIG",
    "Computer Science",
    "BIDA",
    "FALSE"
  ]
];

export const reasonsOptions = [
  { value: "Clarity", label: "Clarity" },
  { value: "Originality", label: "Originality" },
  { value: "Photocopy", label: "Photocopy" },
  { value: "Alteration", label: "Alteration" },
  { value: "Identity mismatch", label: "Identity mismatch" },
];

export const allLevels = [
  { label: "100", value: "100" },
  { label: "200", value: "200" },
  { label: "300", value: "300" },
  { label: "400", value: "400" },
  { label: "500", value: "500" },
  { label: "600", value: "600" },
  { label: "700", value: "700" },
];

const levels = ["All", "100", "200", "300", "400", "500", "600"];
export const levelOptions = levels.map((level) => ({
  label: level === "All" ? "All Levels" : level,
  value: level,
}));

export const additionalFees = [
  { label: "FACULTY FEE", value: "3" },
  { label: "SUG FEE", value: "4" },
  { label: "GST FEE", value: "5" },
  { label: "ENTERPRENEUR FEE", value: "6" },
  { label: "NANISS FEE", value: "7" },
  { label: "E-DOCUMENTATION FEE", value: "22" },
];
