import { ChangeEvent } from "react";
import "./index.scss";
const SearchBox = ({
  setSearchParameter,
  searchParameter,
  callApi,
  onClear,
  placeholder,
}: any) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchParameter(e.target.value);

    if (e.target.value === "" && callApi) {
      onClear();
    }
  };
  return (
    <input
      className="search"
      type="search"
      name="searchParameter"
      value={searchParameter}
      placeholder={placeholder}
      onChange={handleChange}
      onKeyDown={(e: any) => {
        let keyCode = e.code || e.key;
        if (keyCode === "Enter" && callApi) {
          callApi();
        }
      }}
    />
  );
};

export default SearchBox;
