import { ApiService } from "../../../services/apiService";
import { toast } from "react-toastify";

import { routes } from "../../../services/paths";
import { Dispatch } from "redux";
import * as types from "../../action-constants/admissions";
import { handleRequestErrors } from "../../../shared/utils";
import { Student } from "../../../modules/admin-settings/additional-payments/AdditionalPaymentModal";

export const admissionList = (payload: any) => {
  return {
    type: types.FETCH_STUDENTS_ADMISSION_LIST,
    payload,
  };
};

export const admissionListLoading = (payload: boolean) => {
  return {
    type: types.ADMISSION_LIST_LOADING,
    payload,
  };
};

export const facultyAndDept = (payload: any) => {
  return {
    type: types.LOAD_FACULTY_AND_DEPT,
    payload,
  };
};

export const fetchAllProgrammes = (payload: any) => {
  return {
    type: types.LOAD_ALL_ADMISSION_PROGRAMMES,
    payload,
  };
};

export const addAdmittedStudentLoading = (payload: any) => {
  return {
    type: types.ADD_NEW_STUDENT_LOADING,
    payload,
  };
};

export const bulkUploadAdmissionListSuccess = (payload: boolean) => {
  return {
    type: types.BULK_UPLOAD_ADMISSION_LIST_SUCCESS,
    payload,
  };
};

export const loadingBulkUpload = (payload: any) => {
  return {
    type: types.BULK_UPLOAD_LOADING,
    payload,
  };
};

export const createNewStudentSuccess = (payload: any) => {
  return {
    type: types.CREATE_NEW_STUDENT_SUCCESS,
    payload,
  };
};

export const clearState = () => {
  return {
    type: types.CLEAR_STATE,
  };
};
export const getAdmissionList =
  (pageNumber: number, session: string) => async (dispatch: Dispatch) => {
    dispatch(admissionListLoading(true));
    let apiConsume = ApiService.request({
      url: `${routes.GET_ALL_ADMITTED_STUDENTS}?pageNumber=${pageNumber}&pageSize=20&session=${session}`,
      http_method: "GET",
      data: null,
      headers: { "X-Signature": routes.apiKey },
    });
    apiConsume
      .then(({ data }: any) => {
        dispatch(admissionListLoading(false));
        //destructuring the data from API end-point
        const {
          items,
          hasNextPage,
          hasPreviousPage,
          totalPages,
          totalCount,
          currentPage,
        } = data;

        dispatch(
          admissionList({
            items,
            hasNextPage,
            hasPreviousPage,
            totalPages,
            currentPage,
            totalCount,
          })
        );
      })
      .catch((err: any) => {
        dispatch(admissionListLoading(false));
        return err;
      });
  };

export const loadFacultyAndDept = () => async (dispatch: Dispatch) => {
  let apiConsume = ApiService.request({
    url: `${routes.GET_ALL_FACULTIES_WITH_DEPARTMENT}`,
    http_method: "GET",
    data: null,
    headers: { "X-Signature": routes.apiKey },
  });
  apiConsume
    .then(({ data }: any) => {
      dispatch(facultyAndDept(data));
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const loadAllProgrammes = () => async (dispatch: Dispatch) => {
  let apiConsume = ApiService.request({
    url: `${routes.GET_ALL_PROGRAMMES}`,
    http_method: "GET",
    data: null,
    headers: { "X-Signature": routes.apiKey },
  });
  apiConsume
    .then(({ data }: any) => {
      dispatch(fetchAllProgrammes(data));
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const addStudentToAdmissionList =
  (
    studentInfo: any,
    callback: (value: boolean) => void,
    resetForm: any,
    initialValues: any,
    session: string
  ) =>
  async (dispatch: Dispatch, getState: any) => {
    dispatch(addAdmittedStudentLoading(true));
    const currentState = getState();
    await ApiService.request({
      url: studentInfo?.studentId
        ? `${routes.UPDATE_ADMITTED_STUDENT}`
        : `${routes.ADD_ADMITTED_STUDENT}`,
      http_method: studentInfo?.studentId ? "PUT" : "POST",
      data: studentInfo,
      headers: { "X-Signature": routes.apiKey },
    })
      .then(async (response: any) => {
        if (response.status === 200) {
          toast.success(
            studentInfo?.studentId
              ? "Student admission info updated"
              : "Student added to admission list"
          );
          dispatch(addAdmittedStudentLoading(false));
          await resetForm(initialValues);
          callback(false);
          dispatch<any>(
            getAdmissionList(
              currentState?.admissionReducer?.currentPage,
              session
            )
          );
        }
      })
      .catch((err: any) => {
        if (err?.message.includes("Network Error")) {
          toast.error("Network error, please check your internet");
        }
        dispatch(addAdmittedStudentLoading(false));
        handleRequestErrors(err);
      });
  };

export const bulkUploadAdmissionList =
  (file: any, session: string, callback: (val: boolean) => void) =>
  async (dispatch: Dispatch, getState: any) => {
    dispatch(loadingBulkUpload(true));
    const currentState = getState();
    await ApiService.request({
      url: `${routes.ADMIN_BULK_UPLOAD_ADMISSION_LIST}`,
      http_method: "POST",
      data: file,
      headers: { "X-Signature": routes.apiKey },
    })
      .then(async (response: any) => {
        dispatch(loadingBulkUpload(false));
        if (response.status === 200) {
          toast.success("List uploaded successfully");
          await dispatch<any>(
            getAdmissionList(
              currentState?.admissionReducer?.currentPage,
              session
            )
          );
          callback(false);
        }
      })
      .catch((error: any) => {
        if (error?.message.includes("Network Error")) {
          toast.error("Network error, please check your internet");
        }
        dispatch(loadingBulkUpload(false));
        handleRequestErrors(error);
      });
  };

export const searchStudent =
  (searchQuery: any, pageNumber: number) => async (dispatch: Dispatch) => {
    dispatch(admissionListLoading(true));
    let apiConsume = ApiService.request({
      url: `${routes.SEARCH_ADMITTED_STUDENT}?searchQuery=${searchQuery}&PageNumber=${pageNumber}&PageSize=20`,
      http_method: "GET",
      data: null,
      headers: { "X-Signature": routes.apiKey },
    });
    apiConsume
      .then(({ data }: any) => {
        dispatch(admissionListLoading(false));
        const {
          items,
          hasNextPage,
          hasPreviousPage,
          totalPages,
          totalCount,
          currentPage,
        } = data;
        dispatch(
          admissionList({
            items,
            hasNextPage,
            hasPreviousPage,
            totalPages,
            currentPage,
            totalCount,
          })
        );
      })
      .catch((err: any) => {
        if (err?.message.includes("Network Error")) {
          toast.error("Network error, please check your internet");
        }
        dispatch(admissionListLoading(false));
        handleRequestErrors(err);
      });
  };

export const getMatricNumberFormation = async (programme: string) => {
  return await ApiService.request({
    url: `${routes.GET_MATRIC_NUMBER_FORMATION}?programme=${programme}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  });
};

export const createNewStudent = async (studentInfo: any) => {
  return await ApiService.request({
    url: `${routes.CREATE_NEW_STUDENT}`,
    http_method: "POST",
    headers: { "X-Signature": routes.apiKey },
    data: studentInfo,
  });
};

export const getStudentProfile = async (matricNumber: string) => {
  return await ApiService.request({
    url: `${routes.GET_STUDENT_PROFILE}`,
    http_method: "POST",
    headers: { "X-Signature": routes.apiKey },
    data: { matricNumber },
  });
};

export const addCarryOverCompulsoryFee = async (studentInfo: Student) => {
  return await ApiService.request({
    url: `${routes.ADD_COMPULSORY_FEE}`,
    http_method: "POST",
    headers: { "X-Signature": routes.apiKey },
    data: studentInfo,
  });
};

export const getAllCarryOverAddedPayments = async (pageNumber: number) => {
  return await ApiService.request({
    url: `${
      routes.ALL_CARRYOVER_COMPULSORY_FEES
    }?pageNumber=${pageNumber}&pageSize=${20}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  });
};

export const deleteCarryOverCompulsoryFee = async (id: any) => {
  return await ApiService.request({
    url: `${routes.DELETE_CARRYOVER_COMPULSORY_FEE}?unique=${id}`,
    http_method: "DELETE",
    headers: { "X-Signature": routes.apiKey },
  });
};

export const searchCarryOverCompulsoryFee = async (seachTerm: any, pageNumber: number) => {
  return await ApiService.request({
    url: `${routes.SEARCH_CARRYOVER_COMPULSORY_FEE}?seachTerm=${seachTerm}&pageNumber=${pageNumber}&pageSize=${20}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  });
};
