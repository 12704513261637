import React, { useEffect, useState } from "react";
import Pencil from "../../../assets/pencil.png";
import Table from "react-bootstrap/Table";
import "./index.scss";
import Navigation from "../../../shared/components/navigation/Navigation";
import SettingsModal from "./SettingsModal";
import Skeleton from "react-loading-skeleton";
import { handleRequestErrors } from "../../../shared/utils";
import ReactPaginate from "react-paginate";
import { getPaginatedHostelSiteBlock } from "../../../redux/action/hostels";

export interface hostelBlocks {
  blockName: string;
  gender: string;
  amount: number;
  blockUniqueId: string;
}
export interface BlockDetails {
  siteName: string;

  blockName: string;
  gender: string;
  blockUniqueId: string;
  amount: number;
}

const initialValues: BlockDetails = {
  siteName: "",
  blockName: "",
  gender: "",
  blockUniqueId: "",
  amount: 0,
};

const HostelConfiguration = () => {
  const [show, setShow] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const [data, setData] = useState<any>([]);
  const [addInfo, setAddInfo] = useState(initialValues);
  const [totalCount, setTotalCount] = useState(0);

  const pageCount = Math.ceil(totalCount / 20);

  const fetchedData = async (page: number) => {
    setLoading(true);
    try {
      const request = await getPaginatedHostelSiteBlock(page);
      setTotalCount(request?.data.totalCount);
      setData(request?.data?.items);
    } catch (error: any) {
      handleRequestErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageClick = (event: any) => {
    const newOffset = event.selected + 1;
    fetchedData(newOffset);
  };

  useEffect(() => {
    fetchedData(1);
  }, []);

  return (
    <div className="hst-config-container">
      <div className="back-to-report">
        <Navigation
          to="/app/hostels/settings"
          text="Back to Settings"
        />

        <button
          onClick={() => {
            setShow(true);
          }}
          className="add-btn"
        >
          Add New Block
        </button>
      </div>

      <Table bordered hover>
        <thead>
          <tr>
            <th>Site Name</th>
            <th>Block Name</th>
            <th>Gender</th>
            <th>Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            [...Array(20)].map((_, index) => (
              <tr key={index}>
                {[...Array(4)].map((_, index) => (
                  <td key={index}>
                    <Skeleton height={20} />
                  </td>
                ))}
              </tr>
            ))
          ) : data?.length ? (
            data.map((data: any, index: any) => (
              <tr key={index}>
                <td>{data?.siteName}</td>
                <td>{data.hostelBlocks?.blockName}</td>
                <td>{data.hostelBlocks?.gender}</td>
                <td>{data?.hostelBlocks?.amount}</td>

                <td>
                  <div
                  // style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <img
                      src={Pencil}
                      alt="edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setShow(true);
                        const { siteName, hostelBlocks } = data;
                        setAddInfo({ siteName, ...hostelBlocks });
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <p className="no-results">No results...</p>
          )}
        </tbody>
      </Table>

      <SettingsModal
        setShow={setShow}
        show={show}
        setData={setData}
        initialValues={initialValues}
        addInfo={addInfo}
        setAddInfo={setAddInfo}
      />
      {data?.length ? (
        <div className="result-count">
          <p>
            Showing {data?.length} of {totalCount} results
          </p>
        </div>
      ) : null}
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="pagination_items"
        pageClassName="page_num"
        pageLinkClassName="page_link"
        activeClassName="active_page_link"
        previousClassName="previous_page_link"
        nextClassName="next_page_link"
      />
    </div>
  );
};

export default HostelConfiguration;
