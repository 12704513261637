import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { CiCirclePlus } from "react-icons/ci";
import SearchBox from "../../shared/components/searchbox";
import { AlertMsg } from "../../shared/components/alert-msg";
import { LoadingItem } from "../../shared/components/loading";
import { adminInAppActions } from "../../redux/action/portal";
import { appConstantsPortal } from "../../redux/action-constants/portal";
import { InAppTemplate } from "../../shared/templates/portal";
import DeleteIco from "../../assets/delete.png";
import "./index.scss";
import Navigation from "../../shared/components/navigation/Navigation";

const adminLoadSettingsRequest = async ({
  pageProps,
  payload,
  isSearch,
  callback,
}: any) => {
  await pageProps.adminGetAwardsInViewAction(payload, isSearch, callback);
};

const adminAddToSettingsRequest = async ({
  pageProps,
  payload,
  isAdd,
}: any) => {
  await pageProps.adminAddDeleteAwardInView(payload, isAdd);
};

const AddToItemsModal = ({
  pageProps,
  showAddSettings,
  setShowAddSettings,
  pageSize,
  pageNumber,
  searchText,
}: any) => {
  let addItemRequest = pageProps?.adminAddDeleteAwardInViewRequest;
  let initialValues = { name: "" };
  let checkValidationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
  });

  return (
    <>
      <Modal
        className="settings_modal add_item_modal"
        show={showAddSettings}
        onHide={() => {
          if (!addItemRequest?.is_request_processing) {
            if (
              addItemRequest?.request_status ===
              appConstantsPortal?.ADD_DELETE_AWARDS_IN_VIEW_SUCCESS
            ) {
              let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;

              payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}${
                searchText ? `&searchQuery=${searchText}` : ""
              }`;

              adminLoadSettingsRequest({
                pageProps,
                payload,
                isSearch: searchText ? true : false,
              });
            }
            setShowAddSettings(false);
          }
        }}
      >
        {addItemRequest?.request_status !==
          appConstantsPortal?.ADD_DELETE_AWARDS_IN_VIEW_SUCCESS && (
          <div className="add_item_wrap">
            <div className="heading_text">Add new Award-In-View</div>
            <Formik
              initialValues={initialValues}
              validationSchema={checkValidationSchema}
              onSubmit={async (values) => {
                let { name } = values;
                let payload: any = {
                  name,
                  uniqueId: "",
                };
                await adminAddToSettingsRequest({
                  pageProps,
                  payload,
                  isAdd: true,
                });
              }}
            >
              {(props: FormikProps<any>) => {
                const {
                  values,
                  touched,
                  errors,
                  // handleBlur,
                  handleChange,
                  // isSubmitting,
                } = props;
                return (
                  <Form>
                    <div className="form-group">
                      <label className="label">Award-In-View </label>
                      <div
                        className={
                          errors.name && touched.name
                            ? "invalid-input-wrap"
                            : ""
                        }
                      >
                        <Field
                          value={values.name}
                          id="name"
                          name="name"
                          type="text"
                          onChange={handleChange("name")}
                          placeholder="Enter Award"
                          className={`input-wrap ${
                            errors.name && touched.name
                              ? "is-invalid-input "
                              : !errors.name && values.name !== ""
                              ? "valid-input"
                              : ""
                          }`}
                        />
                      </div>
                      <ErrorMessage
                        name="name"
                        className="form-input-error"
                        component="div"
                      />
                    </div>

                    {addItemRequest.request_status ===
                      appConstantsPortal?.ADD_DELETE_AWARDS_IN_VIEW_FAILURE && (
                      <AlertMsg
                        type="error"
                        message={addItemRequest?.request_data.error}
                      />
                    )}

                    <div className="submit_cta">
                      <button
                        disabled={addItemRequest?.is_request_processing}
                        className="btn shaded"
                        type="submit"
                      >
                        {addItemRequest?.is_request_processing
                          ? "Please wait..."
                          : "Submit"}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        )}
        {addItemRequest?.request_status ===
          appConstantsPortal?.ADD_DELETE_AWARDS_IN_VIEW_SUCCESS && (
          <div className="w-50-a">
            <AlertMsg type="success" message="Successfully added" />
          </div>
        )}
      </Modal>
    </>
  );
};

const DeleteFromItemsModal = ({
  pageProps,
  showDeleteSettings,
  setShowDeleteSettings,
  itemToDelete,
  pageSize,
  pageNumber,
  searchText,
}: any) => {
  let deleteRequest = pageProps?.adminAddDeleteAwardInViewRequest;

  const inititateDelete = () => {
    let payload = `?uniqueId=${itemToDelete?.uniqueId}`;
    adminAddToSettingsRequest({
      pageProps,
      payload,
      isAdd: false,
    });
  };

  return (
    <>
      <Modal
        className="settings_modal"
        show={showDeleteSettings}
        onHide={() => {
          if (!deleteRequest?.is_request_processing) {
            if (
              deleteRequest?.request_status ===
              appConstantsPortal?.ADD_DELETE_AWARDS_IN_VIEW_SUCCESS
            ) {
              let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;

              payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}${
                searchText ? `&searchQuery=${searchText}` : ""
              }`;

              adminLoadSettingsRequest({
                pageProps,
                payload,
                isSearch: searchText ? true : false,
              });
            }
            setShowDeleteSettings(false);
          }
        }}
      >
        <Modal.Body>
          {deleteRequest?.request_status !==
            appConstantsPortal?.ADD_DELETE_AWARDS_IN_VIEW_SUCCESS && (
            <>
              <div className="confirm_text">
                Confirm you want to delete <span>{itemToDelete?.name}</span> as
                one of <span>Awards-In-View</span>{" "}
              </div>

              {deleteRequest?.request_status ===
                appConstantsPortal?.ADD_DELETE_AWARDS_IN_VIEW_FAILURE && (
                <div className="w-50-a">
                  <AlertMsg
                    type="error"
                    message={deleteRequest?.request_data.error}
                  />
                </div>
              )}
              <div className="cta_wrap">
                <button
                  onClick={inititateDelete}
                  className="btn"
                  disabled={deleteRequest?.is_request_processing}
                >
                  {deleteRequest?.is_request_processing
                    ? "Deleting..."
                    : "Confirm Delete"}
                </button>
              </div>
            </>
          )}
          {deleteRequest?.request_status ===
            appConstantsPortal?.ADD_DELETE_AWARDS_IN_VIEW_SUCCESS && (
            <div className="w-50-a">
              <AlertMsg type="success" message="Successfully deleted" />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

const SettingsContent = ({
  pageProps,
  pageSize,
  pageNumber,
  allData,
  searchText,
  setSearchText,
  initiateSearch,
  setShouldSearch,
  itemsPerPage,
  callWithoutSearch,
}: any) => {
  const [itemOffset, setItemOffset] = useState(0);
  const [showAddSettings, setShowAddSettings] = useState<boolean>(false);
  const [showDeleteSettings, setShowDeleteSettings] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<any>();

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = allData.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(allData.length / itemsPerPage);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % allData.length;

    setItemOffset(newOffset);
  };

  return (
    <div>
       <div className="back-to-portal">
      <Navigation to="/app/portal-settings" text = "Back to Portal Settings"/>
      </div>
      <div className="settings_header">
        {showDeleteSettings && itemToDelete && (
          <DeleteFromItemsModal
            pageProps={pageProps}
            showDeleteSettings={showDeleteSettings}
            setShowDeleteSettings={setShowDeleteSettings}
            itemToDelete={itemToDelete}
            pageSize={pageSize}
            searchText={searchText}
            pageNumber={pageNumber}
          />
        )}

        {showAddSettings && (
          <AddToItemsModal
            pageProps={pageProps}
            showAddSettings={showAddSettings}
            setShowAddSettings={setShowAddSettings}
            pageSize={pageSize}
            searchText={searchText}
            pageNumber={pageNumber}
          />
        )}

        <SearchBox
          placeholder="Search..."
          setSearchParameter={setSearchText}
          searchParameter={searchText}
          callApi={initiateSearch}
          onClear={callWithoutSearch}
        />

        <div
          className="action-button"
          onClick={() => {
            adminAddToSettingsRequest({
              pageProps,
              payload: "CLEAR",
              isAdd: false,
            });
            setShowAddSettings(true);
          }}
        >
          {" "}
          <CiCirclePlus style={{ width: "20px", height: "20px" }} />
          <span>Add</span>
        </div>
      </div>
      <div className="table_head">
        <div className="settings_name">AWARD IN VIEW </div>
        <div className="settings_action">ACTIONS</div>
      </div>
      <div className="all_settings_items">
        {currentItems &&
          currentItems.length >= 1 &&
          currentItems.map((eachItem: any, index: any) => {
            return (
              <div className="each-settings" key={index}>
                <div className="setting-option">{eachItem?.name}</div>
                <div className="setting-action">
                  {" "}
                  <span
                    onClick={() => {
                      adminAddToSettingsRequest({
                        pageProps,
                        payload: "CLEAR",
                        isAdd: false,
                      });
                      setItemToDelete(eachItem);
                      setShowDeleteSettings(true);
                    }}
                  >
                    <img src={DeleteIco} alt="" />
                  </span>{" "}
                </div>
              </div>
            );
          })}
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="pagination_items"
        pageClassName="page_num"
        pageLinkClassName="page_link"
        activeClassName="active_page_link"
        previousClassName="previous_page_link"
        nextClassName="next_page_link"
      />
    </div>
  );
};

const AwardsSettings = ({ pageProps }: any) => {
  const [pageSize, setPageSize] = useState<any>(200);
  const [pageNumber, setPageNumber] = useState<any>(1);
  const itemsPerPage = 30;
  const [searchText, setSearchText] = useState<any>();
  const [isSearch, setShouldSearch] = useState<boolean>(false);
  let requestInfo = pageProps?.adminGetAwardsInViewRequest;
  useEffect(() => {
    let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;
    if (isSearch) {
      payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}${
        searchText ? `&searchQuery=${searchText}` : ""
      }`;
    }

    adminLoadSettingsRequest({
      pageProps,
      payload,
      isSearch,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;
    if (isSearch) {
      payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}${
        searchText ? `&searchQuery=${searchText}` : ""
      }`;

      adminLoadSettingsRequest({
        pageProps,
        payload,
        isSearch: isSearch && searchText ? true : false,
        callback: () => setShouldSearch(true),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearch]);
  useEffect(() => {
    if (isSearch) {
      if (searchText.length === 0) {
        setShouldSearch(true);
      } else {
        setShouldSearch(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const initiateSearch = () => {
    setShouldSearch(true);
  };
  const callWithoutSearch = () => {
    let payload = `?PageNumber=${pageNumber}&PageSize=${pageSize}`;

    adminLoadSettingsRequest({
      pageProps,
      payload,
      isSearch: false,
    });
  };

  return (
    <div className="settings_wrap_content">
      {requestInfo?.request_status ===
        appConstantsPortal?.GET_AWARDS_IN_VIEW_PENDING && <LoadingItem />}
      {requestInfo?.request_status ===
        appConstantsPortal?.GET_AWARDS_IN_VIEW_FAILURE && (
        <div className="w-50-a">
          <AlertMsg type="error" message={requestInfo?.request_data.error} />
        </div>
      )}
      {requestInfo?.request_status ===
        appConstantsPortal?.GET_AWARDS_IN_VIEW_SUCCESS && (
        <SettingsContent
          allData={requestInfo?.request_data?.response?.data?.items}
          pageProps={pageProps}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          searchText={searchText}
          setShouldSearch={setShouldSearch}
          setSearchText={setSearchText}
          itemsPerPage={itemsPerPage}
          initiateSearch={initiateSearch}
          callWithoutSearch={callWithoutSearch}
        />
      )}
    </div>
  );
};

const AdminAwardsInViewSettingsWrap = (pageProps: any) => {
  return (
    <InAppTemplate
      pageTitle={`Award In View Settings`}
      childComponent={<AwardsSettings pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  adminGetAwardsInViewAction: adminInAppActions.AdminGetAwardsInView,
  adminAddDeleteAwardInView: adminInAppActions.AdminAddDeleteAwardInView,
};
const mapStateToProps = (state: any) => ({
  adminGetAwardsInViewRequest:
    state.allUGradPortalReducers.adminGetAwardsInViewReducer,
  adminAddDeleteAwardInViewRequest:
    state.allUGradPortalReducers.adminAddDeleteAwardInViewReducer,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminAwardsInViewSettingsWrap);
