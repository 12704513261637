import { ErrorMessage } from "formik";
import DatePicker from "react-datepicker";
import { useEffect, useState } from "react";
import { Container, Modal } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Form, Formik, FormikProps } from "formik";
import Button from "react-bootstrap/Button";
import * as Yup from "yup";
import "./index.scss";
import { DotLoader } from "react-spinners";
import { formatDate } from "../../../postutme/reports/Reports";
import { toast } from "react-toastify";
import ReportSearchTable from "./ReportSearchTable";
import {
  downloadHostelReport,
  getAccomodationReport,
} from "../../../../redux/action/hostels";
import DownloadHostelReportModal from "./DownloadHostelReportModal";

type Payload = {
  fromDate: string;
  toDate: string;
  fileType: string;
};

const ReportModal = ({ showFilter, setShowFilter }: any) => {
  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [show, setShow] = useState<any>([]);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");
  const [initialFromDate, setInitialFromDate] = useState("");
  const [initialToDate, setInitialToDate] = useState("");
  const fileType = "Excel";

  const startDateString = "2024-02-06T00:00:00.000Z";
  const endDateString = "2024-03-06T00:00:00.000Z";

  const formattedStartDate = formatDate(startDateString);
  const formattedEndDate = formatDate(endDateString);

  let checkValidationSchema = Yup.object().shape({
    from: Yup.string().required("Required"),
    to: Yup.string().required("Required"),
  });

  const handleSubmit = async () => {
    setLoading(true);

    const payLoad: Payload = {
      fromDate: fromDate,
      toDate: toDate,
      fileType,
    };
    try {
      const response: any = await downloadHostelReport(payLoad);
      setLoading(true);
      if (response?.status === 200) {
        setDownloadUrl(response.data);
        setShowDownloadModal(true);
      } else {
        toast.info("No record found.");
      }
    } catch (error) {
      toast.error("An error occurred while downloading the report.");
    } finally {
      setLoading(false);
    }
  };

  const isISODateString = (dateString: string) => {
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
    return isoDatePattern.test(dateString);
  };

  useEffect(() => {
    if (toDate && fromDate) {
      // Parse the date string
      if (!isISODateString(fromDate)) {
        const from = new Date(fromDate);

        // Convert to ISO format
        const formattedFromDate = from.toISOString();
        setInitialFromDate(formattedFromDate);
      }
      if (!isISODateString(toDate)) {
        const to = new Date(toDate);

        // Convert to ISO format
        const formattedToDate = to.toISOString();
        setInitialToDate(formattedToDate);
      }
    }
  }, [toDate, fromDate]);
  return (
    <div>
      {downloadUrl && (
        <DownloadHostelReportModal
          showDownloadModal={showDownloadModal}
          setShowDownloadModal={setShowDownloadModal}
          downloadUrl={downloadUrl}
          fileType={fileType}
          dateString={`${formattedStartDate}_${formattedEndDate}`}
        />
      )}

      <Modal
        show={showFilter}
        onHide={() => setShowFilter(false)}
        className="course_subject_wrap"
        contentClassName="course-subject-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Hostel Report Search
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="student-modal-form">
          <Container>
            <Formik
              initialValues={{ from: initialFromDate, to: initialToDate }}
              validationSchema={checkValidationSchema}
              onSubmit={async (values) => {
                setLoading(true);
                const { to, from } = values;

                const response = await getAccomodationReport(to, from);
                setLoading(false);
                if (response?.status === 200) {
                  setShow(true);
                  setShowFilter(false);
                }
                if (response.data?.totalCount > 0) {
                  setData(response.data.items);
                } else if (response?.data?.totalCount === 0) {
                  toast.error("No record found");
                } else {
                  toast.error("An error occured");
                }
              }}
            >
              {(props: FormikProps<any>) => {
                const { setFieldValue, setFieldTouched } = props;

                return (
                  <Form>
                    <Row>
                      <div className="date-container">
                        <div className="form-group">
                          <label className="label">From</label>
                          <div>
                            <DatePicker
                              selected={fromDate}
                              onChange={(date: any) => {
                                setFromDate(date);
                                setFieldTouched("from", true);
                                setFieldValue("from", date.toISOString());
                              }}
                              maxDate={new Date()}
                              placeholderText="From date"
                              showYearDropdown
                              showMonthDropdown
                            />
                          </div>
                          <ErrorMessage
                            name="from"
                            className="form-input-error"
                            component="div"
                          />
                        </div>

                        <div className="form-group">
                          <label className="label">To</label>
                          <div>
                            <DatePicker
                              selected={toDate}
                              onChange={(date: any) => {
                                setToDate(date);
                                setFieldTouched("to", true);
                                setFieldValue("to", date.toISOString());
                              }}
                              placeholderText="To"
                              showYearDropdown
                              showMonthDropdown
                            />
                            <ErrorMessage
                              name="to"
                              className="form-input-error"
                              component="div"
                            />
                          </div>
                        </div>
                      </div>
                    </Row>

                    <div className="modal-footer">
                      <Button
                        className="submit-btn"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? (
                          <DotLoader
                            color="white"
                            loading={loading}
                            size={30}
                            aria-label="Submitting"
                          />
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </Container>
        </Modal.Body>
      </Modal>

      <div className="report-table">
        {data.length > 0 && (
          <ReportSearchTable
            show={show}
            items={data}
            handleSubmit={handleSubmit}
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </div>
    </div>
  );
};

export default ReportModal;
