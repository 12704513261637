import { ApiService } from "../../../services/apiService";
import { toast } from "react-toastify";

import { routes } from "../../../services/paths";
import { Dispatch } from "redux";
import * as types from "../../action-constants/putme";
import { handleRequestErrors } from "../../../shared/utils";

export const getAllCourses = (payload: any) => {
  return {
    type: types.GET_POST_PROGRAMS_UTME,
    payload,
  };
};

export const getPutmeReports = (payload: any) => {
  return {
    type: types.GET_POST_UTME_REPORTS,
    payload,
  };
};
export const getPutmeStudents = (payload: any) => {
  return {
    type: types.GET_ALL_POST_UTME_STUDENTS,
    payload,
  };
};
export const getPutmeStudentsLoading = (payload: any) => {
  return {
    type: types.GET_PUTME_STUDENTS_LOADING,
    payload,
  };
};

export const bulkUploadingPostUtmeList = (payload: any) => {
  return {
    type: types.BULK_UPLOAD_LOADING,
    payload,
  };
};

export const updatePutmeLoading = (payload: any) => {
  return {
    type: types.UPDATE_PUTME_LOADING,
    payload,
  };
};

export const getAllProgrammesPutme = () => async (dispatch: Dispatch) => {
  let apiConsume = ApiService.request({
    url: `${routes.GET_PROGRAMS_PUTME}`,
    http_method: "GET",
    data: null,
    headers: { "X-Signature": routes.apiKey },
  });
  apiConsume
    .then(({ data }: any) => {
      dispatch(getAllCourses(data));
    })
    .catch((err: any) => {
      handleRequestErrors(err);
      return err;
    });
};

export const getPostUtmeReports = async (
  to: any,
  from: any,
  programmeType: string,
  programme: string,
  fileType: string
) => {
  try {
    const reportType = fileType === "pdf" ? routes.GET_PUTME_STUDENTS_REPORT : routes.GET_PUTME_STUDENTS_REPORT_EXCEL
    const response = await ApiService.request({
      url: `${reportType}?from=${from}&to=${to}&programmeType=${programmeType}&programme=${programme}`,
      http_method: "GET",
      data: null,
      headers: { "X-Signature": routes.apiKey },
    });

    return response;
  } catch (err: any) {
    if (err?.message.includes("Network Error")) {
      toast.error("Network error, please check your internet");
    } else {
      toast.error("An error occured");
    }
    handleRequestErrors(err);
    return err;
  }
};


export const getPostUmeStudents =
  (pageNumber: number) => async (dispatch: Dispatch) => {
    dispatch(getPutmeStudentsLoading(true));

    let apiConsume1 = ApiService.request({
      url: `${routes.GET_CURRENT_POST_UTME_SESSION}`,
      http_method: "GET",
      data: null,
      headers: { "X-Signature": routes.apiKey },
    });

    apiConsume1
      .then(({ data }: any) => {

        let data1 =  data
        let apiConsume = ApiService.request({
          url: `${routes.GET_ALL_PUTME_STUDENTS}?pageNumber=${pageNumber}&pageSize=20`,
          http_method: "GET",
          data: null,
          headers: { "X-Signature": routes.apiKey },
        });
        apiConsume
          .then(({ data }: any) => {
            dispatch(getPutmeStudentsLoading(false));
            const {
              items,
              hasNextPage,
              hasPreviousPage,
              totalPages,
              totalCount,
              currentPage,
            } = data;
            const {session}= data1
            dispatch(
              getPutmeStudents({
                items,
                hasNextPage,
                hasPreviousPage,
                totalPages,
                totalCount,
                currentPage,
                session
              })
            );
          })
          .catch((err: any) => {
            dispatch(getPutmeStudentsLoading(false));
            return err;
          });
      })
      .catch((err: any) => {
        dispatch(getPutmeStudentsLoading(false));
        return err;
      });
  };

export const searchPutmeStudents =
  (searchQuery: string, pageNumber: number) => async (dispatch: Dispatch) => {
    dispatch(getPutmeStudentsLoading(true));
    let apiConsume = ApiService.request({
      url: `${routes.SEARCH_PUTME_STUDENTS}?pageNumber=${pageNumber}&searchQuery=${searchQuery}&pageSize=20`,
      http_method: "GET",
      data: null,
      headers: { "X-Signature": routes.apiKey },
    });
    apiConsume
      .then(({ data }: any) => {
        dispatch(getPutmeStudentsLoading(false));
        const {
          items,
          hasNextPage,
          hasPreviousPage,
          totalPages,
          totalCount,
          currentPage,
        } = data;
        dispatch(
          getPutmeStudents({
            items,
            hasNextPage,
            hasPreviousPage,
            totalPages,
            totalCount,
            currentPage,
          })
        );
      })
      .catch((err: any) => {
        dispatch(getPutmeStudentsLoading(false));
        return err;
      });
  };

export const bulkUploadPostUtmeList =
  (data: any, callback: (val: boolean) => void) =>
    async (dispatch: Dispatch, getState: any) => {
      dispatch(bulkUploadingPostUtmeList(true));
      const currentState = getState();
      await ApiService.request({
        url: `${routes.BULK_UPLOAD_POST_UTME_LIST}`,
        http_method: "POST",
        data,
        headers: { "X-Signature": routes.apiKey },
      })
        .then(async (response: any) => {
          dispatch(bulkUploadingPostUtmeList(false));
          if (response.status === 200) {
            toast.success("List uploaded successfully");
            await dispatch<any>(
              getPostUmeStudents(currentState?.admissionReducer?.currentPage)
            );
            callback(false);
          }
        })
        .catch((error: any) => {
          if (error?.message.includes("Network Error")) {
            toast.error("Network error, please check your internet");
          } 
          else {
            toast.error("Invalid excel file format. Please ensure the excel file has the correct format");
          }
          dispatch(bulkUploadingPostUtmeList(false));
          handleRequestErrors(error);
        });
    };

export const updatePutmeStudent =
  (
    studentInfo: any,
    callback: (value: boolean) => void,
    resetForm: any,
    initialValues: any,
 ) =>
  async (dispatch: Dispatch, getState: any) => {
    dispatch(updatePutmeLoading(true));
    const currentState = getState();
    await ApiService.request({
      url:`${routes.UPDATE_PUTME_STUDENT}`,
      http_method: "PUT" ,
      data: studentInfo,
      headers: { "X-Signature": routes.apiKey },
    })
      .then(async (response: any) => {
        if (response.status === 200) {
          toast.success(
            studentInfo?.registrationNumber
              && "Student info updated"
          );
          dispatch(updatePutmeLoading(false));
          await resetForm(initialValues);
          callback(false);
          dispatch<any>(
            getPostUmeStudents(
              currentState?.putmeReducer?.currentPage,
            )
          );
        
        }
      })
      .catch((err: any) => {
        if (err?.message.includes("Network Error")) {
          toast.error("Network error, please check your internet");
        }
       dispatch(updatePutmeLoading(false));
        handleRequestErrors(err);
      });
  };


  export const getPutmeStat = async () => {
    return await ApiService.request({
      url: `${routes.GET_PUTME_DASHBOARD_STAT}`,
      http_method: "GET",
      headers: { "X-Signature": routes.apiKey },
    })
      .then((data: any) => {
        return data;
      })
      .catch((err: any) => {
        handleRequestErrors(err);
        return err;
      });
  };



  export const getPostUtmeSession = async () => {
    return await ApiService.request({
      url: `${routes.GET_CURRENT_POST_UTME_SESSION}`,
      http_method: "GET",
      headers: { "X-Signature": routes.apiKey },
    })
      .then((data: any) => {
        return data;
      })
      .catch((err: any) => {
        handleRequestErrors(err);
        return err;
      });
  };

  export const getPassFeedReports = async (programmeType: string, ) => {
    try {
      let reportType;
      if (programmeType === "UTME") {
        reportType = routes.GET_UTME_OLEVEL_PASSFEED_REPORT;
      } else if (programmeType === "DE - ALEVEL") {
        reportType = routes.GET_DE_ALEVEL_PASSFEED_REPORT;
      } else {
        reportType = routes.GET_DE_OLEVEL_PASSFEED_REPORT;
      }
      const response = await ApiService.request({
        url: `${reportType}`,
        http_method: "GET",
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
  
      return response?.data;
    } catch (err: any) {
      if (err?.message.includes("Network Error")) {
        toast.error("Network error, please check your internet");
      } else {
        toast.error("An error occured");
      }
      handleRequestErrors(err);
      return err;
    }
  };

  export const getPassFeedWithScratchCardReports = async (programmeType: string, ) => {
    try {
      let reportType;
      if (programmeType === "UTME") {
        reportType = routes.GET_UTME_OLEVEL_PASSFEED_SCRATCHCARD_REPORT;
      } else if (programmeType === "DE - OLEVEL") {
        reportType = routes.GET_DE_OLEVEL_PASSFEED_SCRATCHCARD_REPORT;
      } else if(programmeType === "DE - ALEVEL"){
        // return
        return toast("No record found");
      } else{
        return;
      }
      const response = await ApiService.request({
        url: `${reportType}`,
        http_method: "GET",
        data: null,
        headers: { "X-Signature": routes.apiKey },
      });
  
      return response?.data;
    } catch (err: any) {
      if (err?.message.includes("Network Error")) {
        toast.error("Network error, please check your internet");
      } else {
        toast.error("An error occured");
      }
      handleRequestErrors(err);
      return err;
    }
  };

  export const getPutmeEligibilityReport = async () => {
    return await ApiService.request({
      url: `${routes.GET_PUTME_ELIGIBILITY_REPORT}`,
      http_method: "GET",
      headers: { "X-Signature": routes.apiKey },
    })
      .then((data: any) => {
        return data;
      })
      .catch((err: any) => {
        handleRequestErrors(err);
        return err;
      });
  };
  
