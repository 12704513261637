import { Link } from "react-router-dom";
import { InAppTemplate } from "../../shared/templates/portal/";

const EOReportDashboard = () => {
  return (
    <div className="settings_list">
      <div className="each_setting">
        <div className="setting_name">Exam Registration Reports</div>
        <div className="setting_link">
          <Link className="btn" to="/app/eo-report">
            Generate Reports
          </Link>{" "}
        </div>
      </div>
      <div className="each_setting">
        <div className="setting_name">Registration Reports</div>
        <div className="setting_link">
          <Link className="btn" to="/app/eo-registration-reports">
            Generate Reports
          </Link>{" "}
        </div>
      </div>
    </div>
  );
};

const EOReportsWrapper = () => {
  return (
    <InAppTemplate
      pageTitle={`EO-Reports`}
      childComponent={<EOReportDashboard />}
    />
  );
};

export default EOReportsWrapper;
