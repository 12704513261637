import { InAppTemplate } from "../../shared/templates/portal/";
import "./index.scss";
import { ToastContainer } from "react-toastify";
import PortalSettingsCard from "./PortalSettingsCard";
const SettingDashboard = () => {
  return (
    <div className="settings_list">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />
      <PortalSettingsCard />
    </div>
  );
};

const AdminSettingsWrap = () => {
  return (
    <InAppTemplate
      pageTitle={`Admin Settings`}
      childComponent={<SettingDashboard />}
    />
  );
};

export default AdminSettingsWrap;
