import { useCallback, useEffect, useState } from "react";
import SearchBox from "../../shared/components/searchbox";
import { ReactComponent as Upload } from "../../assets/upload-icon.svg";
import { CiCirclePlus } from "react-icons/ci";
import "../admission-mgt/index.scss";
import ReactPaginate from "react-paginate";
import fetchAndDownloadExcel from "../../shared/_helpers/downloadexcel";
import { useDispatch, useSelector } from "react-redux";
import CourseTable from "./CourseTable";
import CourseModal from "./CourseModal";
import "./index.scss";
import {
  getAllCourseSubjects,
  searchCourse,
} from "../../redux/action/course-subject";
import CourseBulkUpload from "./CourseBulkUploadModal";
import { courseUploadSampleData } from "../../shared/_helpers/constants";

const CourseSubject = () => {
  const initialValues = {
    courseUniqueId: "",
    courseName: "",
    courseCode: "",
    courseUnit: "",
    semester: "",
    level: 0,
    departmentUniqueId: "",
    programmeUniqueId: "",
  };
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [courseInfo, setCourseInfo] = useState<any>(initialValues);
  const [selectedDepartment, setSelectedDepartment] = useState<string>("");
  const [show, setShow] = useState<boolean>(false);
  const [searchParameter, setSearchParameter] = useState<string>("");
  const { items, totalCount } = useSelector(
    (state: any) => state.courseSubjectReducer
  );
  const dispatch = useDispatch();

  const pageCount = Math.ceil(totalCount / 20);

  const handleSearch = useCallback(
    (searchParam: any, pageNumber: number) => {
      dispatch(searchCourse(searchParam, pageNumber));
    },
    [dispatch]
  );
  const handlePageClick = (event: any) => {
    const newOffset = event.selected + 1;
    if (searchParameter.length) {
      dispatch(searchCourse(searchParameter, newOffset));
    } else {
      dispatch(getAllCourseSubjects(newOffset));
    }
  };

  useEffect(() => {
    let searchDebounce: any;
    if (searchParameter.length) {
      searchDebounce = setTimeout(() => {
        handleSearch(searchParameter, 1);
      }, 1000);
    } else {
      dispatch(getAllCourseSubjects(1));
    }
    return () => clearTimeout(searchDebounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParameter, searchCourse, dispatch]);

  return (
    <div className="admission-management course-subject">
      <p
        className="admission-upload-template"
        onClick={() => fetchAndDownloadExcel(courseUploadSampleData)}
      >
        NOTE : PLEASE CLICK HERE TO DOWNLOAD SAMPLE EXCEL TEMPLATE FOR COURSE
        BULK UPLOAD. ENSURE YOUR UPLOAD MATCHES THE EXCEL TEMPLATE COLUMNS.
      </p>
      <div className="admission-action-buttons">
        <SearchBox
          placeholder="Search..."
          setSearchParameter={setSearchParameter}
          searchParameter={searchParameter}
        />
        <div className="admission-action-button" onClick={() => setShow(true)}>
          {" "}
          <span>Add Single Course</span>
          <CiCirclePlus style={{ width: "20px", height: "20px" }} />
        </div>
        <div
          className="admission-action-button"
          onClick={() => setShowUpload(true)}
        >
          <span>Upload Bulk</span>
          <Upload style={{ width: "20px", height: "20px" }} />
        </div>
      </div>
      <CourseTable
        courseList={items}
        setCourseInfo={setCourseInfo}
        setShow={setShow}
        setSelectedDepartment={setSelectedDepartment}
      />
      <CourseBulkUpload show={showUpload} setShow={setShowUpload} />
      <CourseModal
        show={show}
        setShow={setShow}
        courseInfo={courseInfo}
        setCourseInfo={setCourseInfo}
        initialValues={initialValues}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
      />
      {items?.length ? (
        <div className="result-count">
          <p>
            Showing {items?.length} of {totalCount} results
          </p>
        </div>
      ) : null}
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="pagination_items"
        pageClassName="page_num"
        pageLinkClassName="page_link"
        activeClassName="active_page_link"
        previousClassName="previous_page_link"
        nextClassName="next_page_link"
      />
    </div>
  );
};

export default CourseSubject;
