import { InAppTemplate } from "../../../shared/templates/portal";
import HostelConfiguration from "./configure-hostel";


const HostelConfigurationWrapper = () => {
 
  return (
    <InAppTemplate
      childComponent={<HostelConfiguration />}
      pageTitle="Configure Hostel"
    />
  );
};

export default HostelConfigurationWrapper;