import React from 'react'
import { InAppTemplate } from '../../../shared/templates/portal';
import { ToastContainer } from 'react-toastify';
import EligibilityReport from './EligibilityReport';


const PostUtmeEligibity = () => {
    return (
        <>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            style={{ marginTop: "20px" }}
          />
          <InAppTemplate
            childComponent={<EligibilityReport />}
            pageTitle="Eligibility Report"
          />
        </>
      );
    };
    

export default PostUtmeEligibity;
