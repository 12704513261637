import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { AlertMsg } from "../../shared/components/alert-msg";
import { adminInAppActions } from "../../redux/action/portal";
import { appConstantsPortal } from "../../redux/action-constants/portal";
import { InAppTemplate } from "../../shared/templates/portal";
import "./index.scss";
import Navigation from "../../shared/components/navigation/Navigation";

const adminGetAStudentRequest = async ({ pageProps, payload }: any) => {
  await pageProps.adminGetAStudentAction(payload);
};
const adminStudentStudentStatusActionRequest = async ({
  pageProps,
  payload,
}: any) => {
  await pageProps.adminStudentStudentStatusAction(payload);
};

const UpdateStatus = ({ pageProps }: any) => {
  const [actionText, setActionText] = useState<any>();
  let fetchRequestInfo = pageProps.adminGetAStudentRequest;
  fetchRequestInfo = fetchRequestInfo?.request_data?.response?.data;

  let requestInfo = pageProps.adminUpdateStudentStatusRequest;

  const handleUpdate = (action: any) => {
    let payload: any = {
      matricNumber: fetchRequestInfo?.matricNumber,
      isActive: action === "activate",
    };
    setActionText(action);
    adminStudentStudentStatusActionRequest({ pageProps, payload });
  };

  return (
    <>
      {requestInfo.request_status !==
        appConstantsPortal?.UPDATE_A_STUDENT_STATUS_SUCCESS && (
        <div className="confirm_reset">
          <span>{fetchRequestInfo?.fullName}</span> is presently an{" "}
          {fetchRequestInfo?.isActive && <span>Active Student</span>}{" "}
          {!fetchRequestInfo?.isActive && <span>Inactive Student</span>}
          <br /> <br />
          {!fetchRequestInfo?.isActive && (
            <div className="note">
              Do you want to <span>Activate</span>{" "}
              <span>{fetchRequestInfo?.fullName}</span> ?
            </div>
          )}
          {fetchRequestInfo?.isActive && (
            <div className="note">
              Do you want to <span>De-Activate</span>{" "}
              <span>{fetchRequestInfo?.fullName}</span> ?
            </div>
          )}
          <div className="note">
            Please note that this student will{" "}
            {!fetchRequestInfo?.isActive && <span> now have access</span>}{" "}
            {fetchRequestInfo?.isActive && <span> not have access</span>} to the
            student portal.
          </div>
          {fetchRequestInfo?.isActive && (
            <button
              className="btn"
              disabled={requestInfo?.is_request_processing}
              onClick={() => handleUpdate("deactivate")}
            >
              {" "}
              {requestInfo?.is_request_processing
                ? "Please wait..."
                : "De-Activate Student"}
            </button>
          )}
          {!fetchRequestInfo?.isActive && (
            <button
              className="btn"
              disabled={requestInfo?.is_request_processing}
              onClick={() => handleUpdate("activate")}
            >
              {" "}
              {requestInfo?.is_request_processing
                ? "Please wait..."
                : "Activate Student"}
            </button>
          )}
        </div>
      )}
      <div className="student_form">
        {requestInfo.request_status ===
          appConstantsPortal?.UPDATE_A_STUDENT_STATUS_FAILURE && (
          <AlertMsg type="error" message={requestInfo?.request_data.error} />
        )}

        {requestInfo.request_status ===
          appConstantsPortal?.UPDATE_A_STUDENT_STATUS_SUCCESS && (
          <AlertMsg
            type="success"
            message={`${fetchRequestInfo?.fullName} has been successfully ${
              actionText === "activate" ? "Activated" : "De-Activated"
            }`}
          />
        )}
      </div>
    </>
  );
};

const StudentForm = ({ pageProps }: any) => {
  let requestInfo = pageProps.adminGetAStudentRequest;
  let updateStatusRequestInfo = pageProps.adminUpdateStudentStatusRequest;

  let checkValidationSchema = Yup.object().shape({
    matricNumber: Yup.string().required("Required"),
  });
  let initialValues: any = { matricNumber: "" };

  return (
    <div className="student_form">
      <Formik
        initialValues={initialValues}
        validationSchema={checkValidationSchema}
        onSubmit={async (values) => {
          let { matricNumber } = values;
          let payload: any = {
            matricNumber,
          };
          adminStudentStudentStatusActionRequest({
            pageProps,
            payload: "CLEAR",
          });
          await adminGetAStudentRequest({ pageProps, payload });
        }}
      >
        {(props: FormikProps<any>) => {
          const {
            values,
            touched,
            errors,
            // handleBlur,
            handleChange,
            // isSubmitting,
          } = props;
          return (
            <Form>
              <div className="form-group">
                <label className="label">Matric Number </label>
                <div
                  className={
                    errors.matricNumber && touched.matricNumber
                      ? "invalid-input-wrap"
                      : ""
                  }
                >
                  <Field
                    value={values.matricNumber}
                    id="matricNumber"
                    name="matricNumber"
                    type="text"
                    disabled={updateStatusRequestInfo?.is_request_processing}
                    onChange={handleChange("matricNumber")}
                    placeholder="Enter matric number"
                    className={`input-wrap ${
                      errors.matricNumber && touched.matricNumber
                        ? "is-invalid-input "
                        : !errors.matricNumber && values.matricNumber !== ""
                        ? "valid-input"
                        : ""
                    }`}
                  />
                </div>
                <ErrorMessage
                  name="matricNumber"
                  className="form-input-error"
                  component="div"
                />
              </div>

              {requestInfo.request_status ===
                appConstantsPortal?.GET_A_STUDENT_FAILURE && (
                <AlertMsg
                  type="error"
                  message={requestInfo?.request_data.error}
                />
              )}
              <div className="submit_cta">
                <button
                  disabled={
                    requestInfo?.is_request_processing ||
                    updateStatusRequestInfo?.is_request_processing
                  }
                  className="btn shaded"
                  type="submit"
                >
                  {requestInfo?.is_request_processing
                    ? "Please wait..."
                    : "Search Student"}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const StudentPasswordSettings = ({ pageProps }: any) => {
  let requestInfo = pageProps.adminGetAStudentRequest;

  useEffect(() => {
    adminGetAStudentRequest({
      pageProps,
      payload: "CLEAR",
    });
    adminStudentStudentStatusActionRequest({
      pageProps,
      payload: "CLEAR",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="student_password settings_wrap_content">
       <div className="back-to-portal">
      <Navigation to="/app/portal-settings" text = "Back to Portal Settings"/>
      </div>
      <StudentForm pageProps={pageProps} />

      {requestInfo.request_status ===
        appConstantsPortal?.GET_A_STUDENT_SUCCESS && (
        <UpdateStatus pageProps={pageProps} />
      )}
    </div>
  );
};

const AdminStudentStatusUpdateWrap = (pageProps: any) => {
  return (
    <InAppTemplate
      pageTitle={`Activate/De-Activate Student`}
      childComponent={<StudentPasswordSettings pageProps={pageProps} />}
    />
  );
};

const mapDispatchToProps = {
  adminGetAStudentAction: adminInAppActions.AdminGetAStudent,
  adminStudentStudentStatusAction: adminInAppActions.AdminUpdateStatus,
};
const mapStateToProps = (state: any) => ({
  adminGetAStudentRequest: state.allUGradPortalReducers.adminGetAStudentReducer,
  adminUpdateStudentStatusRequest:
    state.allUGradPortalReducers.adminUpdateStatusReducer,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminStudentStatusUpdateWrap);
