import { Link } from "react-router-dom";
import { InAppTemplate } from "../../shared/templates/portal/";
import "./index.scss";
import { useEffect } from "react";
import { getSchoolSessionHistory } from "../../redux/action/onboarding";
import { useDispatch } from "react-redux";

const ReportsDashboard = () => {
  return (
    <div className="settings_list">
      <div className="each_setting">
        <div className="setting_name">New Students Matriculation Report</div>
        <div className="setting_link">
          <Link className="btn" to="/app/reports/new-students-matriculation">
            Generate Reports
          </Link>{" "}
        </div>
      </div>
      <div className="each_setting">
        <div className="setting_name">Matriculation Report</div>
        <div className="setting_link">
          <Link className="btn" to="/app/reports/matriculation">
            Generate Reports
          </Link>{" "}
        </div>
      </div>
      {/* <div className="each_setting">
                <div className="setting_name">New Students Payments</div>
                <div className="setting_link"><Link className="btn" to="/app/reports/new-students-payments">Generate Reports</Link> </div>
            </div> */}
      <div className="each_setting">
        <div className="setting_name">Registration Report</div>
        <div className="setting_link">
          <Link className="btn" to="/app/reports/registration">
            Generate Reports
          </Link>{" "}
        </div>
      </div>

      <div className="each_setting">
        <div className="setting_name">Nelfund Report</div>
        <div className="setting_link">
          <Link className="btn" to="/app/reports/nelfund">
            Generate Reports
          </Link>{" "}
        </div>
      </div>
    </div>
  );
};

const AdminFullReportsWrap = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSchoolSessionHistory());
  }, [dispatch]);
  return (
    <InAppTemplate
      pageTitle={`Reports`}
      childComponent={<ReportsDashboard />}
    />
  );
};

export default AdminFullReportsWrap;
