import { combineReducers } from "redux";


import {
    onboardingReducers
} from "./onboarding/onboarding.reducer"

import {
    inAppOneReducers
} from "./inapp/app.reducer."
import admissionReducer from "./admission";
import putmeReducer from "./putme";
import courseSubjectReducer from "./course-subject";
import edocumentationReducer from './edocumentation'




const rootReducer = (state: any, action: any) => {
    // if (action.type === authConstants.LOGOUT) {
    //     state = undefined;
    // }
    return appReducer(state, action);
};



const allOnboardingReducers = combineReducers({
    adminLogin: onboardingReducers.adminLoginReducer,
    adminForgotPw: onboardingReducers.adminForgotPwReducer,
    adminVerifyForgotPwCode: onboardingReducers.adminVerifyForgotPwCodeReducer,
    adminUpdateNewPassword: onboardingReducers.adminUpdateNewPasswordeReducer,
})

const allUGradPortalReducers = combineReducers({
    adminGetDashboardReducer: inAppOneReducers.adminGetDashboardReducer,
    adminLoadRegMetaReducer: inAppOneReducers.adminLoadRegMetaReducer,
    adminLoadPresetCourseRegReducer: inAppOneReducers.adminLoadPresetCourseRegReducer,
    adminSavePresetCourseRegReducer: inAppOneReducers.adminSavePresetCourseRegReducer,
    adminDeletePresetCourseRegReducer: inAppOneReducers.adminDeletePresetCourseRegReducer,
    
    adminGetAllStudentsReducer: inAppOneReducers.adminGetAllStudentsReducer,
    adminGetAStudentReducer: inAppOneReducers.adminGetAStudentReducer,
    adminUpdateAStudentReducer: inAppOneReducers.adminUpdateAStudentReducer,
    adminGetBioDataMetaReducer: inAppOneReducers.adminGetBioDataMetaReducer,
    adminUpdateAStudentCourseOfStudyReducer: inAppOneReducers.adminUpdateAStudentCourseOfStudyReducer,
    adminGetAStudentProgramChangeHistoryReducer: inAppOneReducers.adminGetAStudentProgramChangeHistoryReducer,
    
    
    adminGetAStudentInvoiceReducer: inAppOneReducers.adminGetAStudentInvoiceReducer,
    adminUpdateAStudentInvoiceReducer: inAppOneReducers.adminUpdateAStudentInvoiceReducer,
    
    adminGetAwardsInViewReducer: inAppOneReducers.adminGetAwardsInViewReducer,
    adminAddDeleteAwardInViewReducer: inAppOneReducers.adminAddDeleteAwardInViewReducer,
    adminGetCountriesReducer: inAppOneReducers.adminGetCountriesReducer,
    adminAddDeleteCountryReducer: inAppOneReducers.adminAddDeleteCountryReducer,
    adminGetQualificationsReducer: inAppOneReducers.adminGetQualificationsReducer,
    adminAddDeleteQualificationsReducer: inAppOneReducers.adminAddDeleteQualificationsReducer,
    adminGetSpecialitiesReducer: inAppOneReducers.adminGetSpecialitiesReducer,
    adminAddDeleteSpecialitiesReducer: inAppOneReducers.adminAddDeleteSpecialitiesReducer,
    adminGetCurrentSessionReducer: inAppOneReducers.adminGetCurrentSessionReducer,
    adminSetCurrentSessionReducer: inAppOneReducers.adminSetCurrentSessionReducer,
    
    adminResetPasswordReducer: inAppOneReducers.adminResetPasswordReducer,
    adminResetAdminReducer: inAppOneReducers.adminResetAdminReducer,
    adminUpdateStatusReducer: inAppOneReducers.adminUpdateStatusReducer,
    adminUpdateStudentOriginReducer: inAppOneReducers.adminUpdateStudentOriginReducer,
    
    adminAssignCourseAdvToDeptReducer: inAppOneReducers.adminAssignCourseAdvToDeptReducer,
    
    adminCreateAUserReducer: inAppOneReducers.adminCreateAUserReducer,
    adminUpdateAUserStatusReducer: inAppOneReducers.adminUpdateAUserStatusReducer,
    adminCreateARoleReducer: inAppOneReducers.adminCreateARoleReducer,
    adminAddUserToARoleReducer: inAppOneReducers.adminAddUserToARoleReducer,
    adminGetAllApisReducer: inAppOneReducers.adminGetAllApisReducer,
    adminGetAllUserRolesReducer: inAppOneReducers.adminGetAllUserRolesReducer,
    adminGetAllUsersReducer: inAppOneReducers.adminGetAllUsersReducer,
    adminGetAUserReducer: inAppOneReducers.adminGetAUserReducer,
    adminLoadNewUserMetaReducer: inAppOneReducers.adminLoadNewUserMetaReducer,
    
    adminGetSchoolFeesReportReducer: inAppOneReducers.adminGetSchoolFeesReportReducer,
    adminDownloadSchoolFeesReportReducer: inAppOneReducers.adminDownloadSchoolFeesReportReducer,
    adminGetAcceptanceFeesReportReducer: inAppOneReducers.adminGetAcceptanceFeesReportReducer,
    adminDownloadAcceptanceFeesReportReducer: inAppOneReducers.adminDownloadAcceptanceFeesReportReducer,
    adminGetAdditionalFeesReportReducer: inAppOneReducers.adminGetAdditionalFeesReportReducer,
    adminDownloadAdditionalFeesReportReducer: inAppOneReducers.adminDownloadAdditionalFeesReportReducer,
    
   
    
    adminGetMatriculationReportReducer:inAppOneReducers.adminGetMatriculationReportReducer,
    adminDownloadMatriculationReportsReducer:inAppOneReducers.adminDownloadMatriculationReportsReducer,
    adminGetNewStudentsPaymentsReportReducer:inAppOneReducers.adminGetNewStudentsPaymentsReportReducer,
    adminDownloadNewStudentsPaymentsReducer: inAppOneReducers.adminDownloadNewStudentsPaymentsReducer,
    adminGetStudentsRegistrationReportReducer:inAppOneReducers.adminGetStudentsRegistrationReportReducer,
    adminDownloadStudentsRegistrationReportReducer:inAppOneReducers.adminDownloadStudentsRegistrationReportReducer,
    adminGetNelFundReducer: inAppOneReducers.adminGetNelFundReducer,
    adminDownloadNelFundReducer: inAppOneReducers.adminGetNelFundDownloadReducer,
    printBioDataReducer: inAppOneReducers.printBioDataReducer,

    adminGetStudentEORegistrationReducer: inAppOneReducers.adminGetEORegistrationReportReducer,
    adminDownloadEORegistrationReducer: inAppOneReducers.adminDownloadEORegistrationReportReducer,
  
})


const appReducer = combineReducers({
    allOnboardingReducers,
    allUGradPortalReducers,
    admissionReducer,
    putmeReducer,
    courseSubjectReducer,
    edocumentationReducer,
 
});

export default rootReducer;